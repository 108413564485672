import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  styled,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import dayjs from "dayjs";

const currentDate = dayjs();
const currentYear = dayjs().year();
const WrapDrawerFilter = styled("div")({
  marginTop: 64,
  padding: 16,
  width: 300,
  "& .wrapBtnReset": {
    marginTop: 16,
    "& Button": {
      width: "100%",
      marginBottom: 8,
    },
  },
});

const StyledDatePicker = styled(DatePicker)({
  backgroundColor: "#ffffff",
  "& .MuiOutlinedInput-root": {
    color: "#000000",
    "& fieldset": {
      borderColor: "#000000",
    },
    "& fieldset-date": {
      borderColor: "#000000",
    },
    "&:hover fieldset": {
      borderColor: "#000000",
    },
    "&:hover fieldset-date": {
      borderColor: "#000000",
    },
    "& .Mui-focused fieldset": {
      borderColor: "#000000",
    },
    "& .Mui-focused fieldset-date": {
      borderColor: "#000000",
    },
  },
  "& .MuiIconButton-root": {
    color: "#000000",
  },
  "& .MuiInputLabel-root": {
    color: "#000000",
  },
});


const AssessmentFilter = ({ setFilterAssessment , setFilterSelectedDate }) => {
  const { result: appraiseeList, isFetching } = useSelector(
    (state) => state.appraisee
  );
  const [filteredAppraisees, setFilteredAppraisees] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    assessmentType: { open: true, close: true },
    status: { ongoing: true, finished: true },
  });
  // const [selectYear, setSelectYear] = useState(currentYear);
  const [selectDate, setSelectDate] = useState(dayjs().startOf("month"));
  const [filterDate, setFilterDate] = useState(dayjs());

  const handleDateChange = (newDate) => {
    if (newDate && dayjs(newDate).isValid()) {
      setSelectDate(newDate);
      console.log("Selected Date in Filter:", newDate.format("YYYY-MM"));
    }
  };

  const handleSearch = () => {
    if (!appraiseeList || isFetching) return;

    const filteredAppraisees = appraiseeList.filter((item) => {
      const isOpen = filterOptions.assessmentType.open && item.type === "open";
      const isClose =
        filterOptions.assessmentType.close && item.type === "close";

      const isOngoing =
        filterOptions.status.ongoing &&
        currentDate.isAfter(dayjs(item.startDate)) &&
        currentDate.isBefore(dayjs(item.endDate)) === "ongoing";

      const isFinished =
        filterOptions.status.finished &&
        currentDate.isAfter(dayjs(item.endDate)) === "finished";

      return (isOpen || isClose) && (isOngoing || isFinished);
    });

    setFilteredAppraisees(filteredAppraisees);
   
    setFilterAssessment({
      ...filterOptions,
      selectDate: selectDate.format("YYYY-MM"),
    });
    setFilterSelectedDate(selectDate); 
  };

  const handleReset = () => {
    setFilterOptions({
      assessmentType: { open: true, close: true },
      status: { ongoing: true, finished: true },
    });

    setFilteredAppraisees([]);
  };

  // const handleChange = (event) => {
  //   setFilterOptions((prevOptions) => {
  //     const newOptions = {
  //       ...prevOptions,
  //       assessmentType: {
  //         ...prevOptions.assessmentType,
  //         [event.target.name]: event.target.checked,
  //       },
  //     };
  //     return newOptions;
  //   });
  // };

  const handleAssessmentTypeChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      assessmentType: {
        ...prevOptions.assessmentType,
        [name]: checked,
      },
    }));
  };

  const handleStatusChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      status: {
        ...prevOptions.status,
        [name]: checked,
      },
    }));
  };

  return (
    <WrapDrawerFilter>
      <Stack spacing={2}>
        {/* <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">ประเภทคำถาม</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.assessmentType.close}
                  onChange={handleAssessmentTypeChange}
                  name="close"
                  color="primary"
                />
              }
              label="คำถามปลายปิด" // Closed questions
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.assessmentType.open}
                  onChange={handleAssessmentTypeChange}
                  name="open"
                  color="primary"
                />
              }
              label="คำถามปลายเปิด" // Open questions
            />
          </FormGroup>
        </FormControl> */}

        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">สถานะการประเมิน</FormLabel>{" "}
          {/* Assessment Status */}
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.status.ongoing}
                  onChange={handleStatusChange}
                  name="ongoing"
                  color="primary"
                  data-type="status"
                />
              }
              label="กำลังดำเนินการ" // Ongoing
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.status.finished}
                  onChange={handleStatusChange}
                  name="finished"
                  color="primary"
                  data-type="status"
                />
              }
              label="เสร็จสิ้นแล้ว" // Finished
            />
          </FormGroup>
        </FormControl>

        <FormControl component="fieldset-date" fullWidth >
          <FormLabel component="legend" sx={{ paddingBottom: "16px" }}>
            วันและเวลา
          </FormLabel>{" "}
          <FormControlLabel
            sx={{ width : "100%", marginLeft:"0.15px"}}
            control={
              <StyledDatePicker
              sx={{ width : "100%" }}
              value={selectDate} 
              onChange={handleDateChange}
                label={"เลือกเดือนและปี"}
                views={["month", "year"]}
                minDate={dayjs(`${currentYear - 1}`)}
                maxDate={dayjs()}
              />
            }
          />
        </FormControl>

        <div className={`wrapBtnReset`}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Stack>
    </WrapDrawerFilter>
  );
};

export default AssessmentFilter;
