import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { getDepartment } from "../../../../../actions/department";
import { addMoraleDaily } from "../../../../../actions/moraleDaily";
import dayjs from "dayjs";
import { toast } from "sonner";

const DialogPublish = ({ open, handleClose, idQuestion }) => {
  const dispatch = useDispatch();
  const { result: department } = useSelector((state) => state.department);

  const [date, setDate] = useState({
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
  });
  const [valueTarget, setValueTarget] = useState("company");
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  const handleChangeValueTarget = (event) => {
    setValueTarget(event.target.value);
  };

  const handlePublish = async () => {
   // console.log("handlePublish function triggered");
    const targetPass = (valueTarget === "department" && selectedDepartment.length > 0) || valueTarget === "company";
    const department = selectedDepartment.map((item) => item.idDepartment);
    
    if (!targetPass) {
      toast.error("โปรดเลือกหน่วยงาน")
      return;
    }

    const payload = {
      idMoraledailyQuestion: idQuestion,
      startDate: date.startDate,
      endDate: date.endDate,
      valueTarget,
      selectedDepartment: department,
    };
  
    try {
      const res = await dispatch(addMoraleDaily(payload));
  
      handleClose();
      //console.log("Response from backend:", res);
  
      if (res?.status === 201) {
        toast.success("ส่งคำถามสำเร็จ");
      } else {
        throw new Error(res?.message || "เกิดข้อผิดพลาด");
      }
    } catch (error) {
      console.error("Publish error:", error.message);
      toast.error("เกิดข้อผิดพลาด");
    }
  };

  useEffect(() => {
    dispatch(getDepartment());
  }, [dispatch]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"เลือกกลุ่มเป้าหมาย"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} marginTop={1} >
          <div style={{ display: "flex", gap: "8px", width: '100%', justifyContent:'space-between'  }}>
            <DateTimePicker
              label="วันที่เริ่มต้น"
              value={date.startDate}
              onChange={(value) => setDate((prev) => ({ ...prev, startDate: value }))}
              format="DD/MM/YYYY HH:mm"
              ampm={false}
              minDate={dayjs()}
              maxDate={date.endDate}
              sx={{width: '100%'}}
            />
            <DateTimePicker
              label="วันที่สิ้นสุด"
              value={date.endDate}
              onChange={(value) => setDate((prev) => ({ ...prev, endDate: value }))}
              format="DD/MM/YYYY HH:mm"
              ampm={false}
              minDate={date.startDate}
              sx={{width: '100%'}}
            />
          </div>
          <FormControl component="fieldset">
            <FormLabel component="legend">กลุ่มเป้าหมาย</FormLabel>
            <RadioGroup
              aria-label="valueTarget"
              name="valueTarget"
              value={valueTarget}
              onChange={handleChangeValueTarget}
              row
            >
              <FormControlLabel
                value="company"
                control={<Radio />}
                label="ทั้งบริษัท"
              />
              <FormControlLabel
                value="department"
                control={<Radio />}
                label="รายหน่วยงาน"
              />
            </RadioGroup>
          </FormControl>
          {valueTarget === "department" && department && (
            <Autocomplete
              multiple
              ListboxProps={{ style: { maxHeight: 320 } }}
              id="fixed-tags-demo-department"
              value={selectedDepartment}
              onChange={(event, newValue) => setSelectedDepartment(newValue)}
              options={department.filter((item) => item.idDepartment)}
              isOptionEqualToValue={(option, value) => option.idDepartment === value.idDepartment}
              getOptionLabel={(option) => option.departmentName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="หน่วยงาน"
                />
              )}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions style={{paddingRight:25, paddingBottom:20}}>
        <Button variant="outlined" color="error" onClick={handleClose}>
          ยกเลิก
        </Button>
        <Button variant="contained" color="success" onClick={handlePublish}>
          เผยแพร่
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPublish;
