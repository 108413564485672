import React, { useState, useEffect, Fragment, createContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Drawer,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import ManagerDashboardFilter from "./filter/filterMoraleDashboardManager";
import {
  useEmployeeLevel,
  useEmploymentType,
  useOrganization,
} from "../../../../quries/company";
import { useMoraleCompany } from "../../../../quries/morale";
import DashboardPageDetail from "./detailManagerDashboard";
import LoadingIcon from "../../shared/general/loadingIcon";

const CompanyContext = createContext();

// const useIdCompany = () => {
//   const { result: userProfile } = useSelector((state) => state.userProfile);
//   return userProfile?.idCompany;
// };

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
});

const RenderSelectedFilter = ({ selectedFilter, handleToggle }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {Object.keys(selectedFilter)
        .filter((element) =>
          selectedFilter[element].itemList?.some((item) => item.checked)
        )
        .map((element) => {
          return (
            <div style={{ display: "flex", gap: "8px" }} key={element}>
              <Chip icon={<FilterAltIcon />} label={element} color="primary" />
              {selectedFilter[element].itemList
                .filter((item) => item.checked)
                .map((item) => (
                  <Chip
                    key={item.value}
                    variant="outlined"
                    label={item.text}
                    sx={{ color: "#ffffff" }}
                    onClick={() => handleToggle(element, item.text)}
                  />
                ))}
            </div>
          );
        })}
    </div>
  );
};

const DashboardPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  // const idCompanyFromRedux = useIdCompany();
  const [loading, setLoading] = useState(true);
  const [moraleSelected, setMoraleSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterSelected, setFilterSelected] = useState(null);
  const toggleDrawer = () => setOpenFilter(!openFilter);

  const organization = useOrganization();
  const moraleCompany = useMoraleCompany({
    idCompany: userProfile?.idCompany,
  });

  const employmentType = useEmploymentType({
    idCompany: companySelected?.idCompany,
  });
  const employmentLevel = useEmployeeLevel({
    idCompany: companySelected?.idCompany,
  });

  useEffect(() => {
    const loadTimeout = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(loadTimeout);
  }, []);

  useEffect(() => {
    if (moraleCompany?.data?.length > 0) {
      setMoraleSelected(moraleCompany.data[0]);
    }
  }, [moraleCompany.data]);

  const handleToggle = (category, item) => {
    setFilterSelected((prevFilters) => {
      if (!prevFilters || !prevFilters[category]?.itemList) return prevFilters;
      const updatedFilters = { ...prevFilters };
      const targetItem = updatedFilters[category].itemList.find(
        (i) => i.text === item
      );
      if (targetItem) {
        targetItem.checked = !targetItem.checked;
      }
      return updatedFilters;
    });
  };

  const StyledAutocomplete = styled(Autocomplete)({
    backgroundColor: "#282828",
    "& .MuiOutlinedInput-root": {
      color: "#ffffff",
    },
    fieldset: {
      borderColor: "#ffffff",
    },
    "& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused": {
      color: "#ffffff",
    },
  });

  useEffect(() => {
    const filterData = {
      gender: {
        label: "Gender",
        itemList: [
          { value: "Female", text: "Female", checked: false },
          { value: "Male", text: "Male", checked: false },
        ],
      },
      generation: {
        label: "Generation",
        itemList: [
          { value: "babyBoomer", text: "Baby Boomer", checked: false },
          { value: "genX", text: "Generation X", checked: false },
          { value: "genY", text: "Generation Y", checked: false },
          { value: "genZ", text: "Generation Z", checked: false },
          { value: "alpha", text: "Alpha", checked: false },
        ],
      },
      jobLevel: {
        label: "Job Level",
        itemList: employmentLevel?.data?.map((item) => ({
          value: item.idLevel,
          text: item.levelName,
          checked: false,
        })),
      },
      jobType: {
        label: "Job Type",
        itemList: employmentType?.data?.map((item) => ({
          value: item.idEmploymentType,
          text: item.employmentTypeName,
          checked: false,
        })),
      },
      // moraleType: {
      //   label: "Morale Type",
      //   itemList: [
      //     { text: "Quarter", value: "quarter", checked: false },
      //     { text: "Year", value: "year", checked: false },
      //   ],
      // },
    };

    setFilterSelected({ ...filterData });
  }, [employmentType.data, employmentLevel.data]);

  // const moraleKPI = useMoraleKPI({
  //   idMorale: moraleCompany?.data?.[0]?.idMorale,
  //   query: {},
  // });

  const handleClickApply = (filterData) => {
    setFilterSelected({ ...filterData });
  };

  const handleClearFilter = () => {
    setFilterSelected({
      gender: { label: "Gender", itemList: [] },
      generation: { label: "Generation", itemList: [] },
      jobLevel: { label: "Job Level", itemList: [] },
      jobType: { label: "Job Type", itemList: [] },
    });
  };

  useEffect(() => {
    if (organization.isSuccess) {
      setCompanySelected(organization.data[0]);
    }
  }, [organization.isSuccess]);

  if (!moraleCompany?.data || moraleCompany?.data.length === 0) {
    return (
      <StyledRoot className={"page"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <LoadingIcon />
        </Box>
      </StyledRoot>
    );
  }

  if (organization.isPending || loading) {
    return (
      <StyledRoot className={"page"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <LoadingIcon />
        </Box>
      </StyledRoot>
    );
  }

  return (
    <CompanyContext.Provider value={userProfile?.idCompany}>
      <div className="page dashboard-page">
        <Typography variant="h4" color="#fff">
          ภาพรวมสุขภาพใจ
        </Typography>
        <Divider style={{ backgroundColor: "#ffffff", margin: "16px 0" }} />
        <Fragment>
          <Typography variant="h5" gutterBottom style={{ color: "#e2e2e2" }}>
            สรุปผลการประเมิน
          </Typography>
          <Fragment>
            {userProfile?.roles?.includes("ROLE_MANAGER") &&
              employmentType.data &&
              employmentLevel.data && (
                <div>
                  {filterSelected && (
                    <RenderSelectedFilter
                      selectedFilter={filterSelected}
                      handleToggle={handleToggle}
                    />
                  )}
                </div>
              )}
            <Grid container spacing={2} alignItems="center" marginBottom={1}>
              <Grid item xs={12} sm={6} md={6}>
                <StyledAutocomplete
                  disableClearable
                  value={moraleSelected}
                  options={moraleCompany.data}
                  loading={moraleCompany.isPending}
                  onChange={(event, newValue) => setMoraleSelected(newValue)}
                  getOptionLabel={(option) =>
                    `${option.moraleName} (${option.moraleType})`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.idMorale === value.idMorale
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Morale" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <Fragment>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "15px",
                        width: "100%",
                      }}
                    >
                      <IconButton
                        aria-label="filter"
                        onClick={toggleDrawer}
                        size="large"
                        style={{
                          backgroundColor: "rgb(65,105,225)",
                          color: "white",
                        }}
                      >
                        <FilterListIcon fontSize="large" />
                      </IconButton>
                    </div>
                    <Drawer
                      anchor="right"
                      open={openFilter}
                      onClose={toggleDrawer}
                    >
                      <ManagerDashboardFilter
                        filter={filterSelected}
                        handleClickApply={handleClickApply}
                        handleClearFilter={handleClearFilter}
                        handleCloseDrawer={toggleDrawer}
                      />
                    </Drawer>
                  </Fragment>
                </div>
              </Grid>
            </Grid>
            {moraleSelected && (
              <DashboardPageDetail
                idMorale={moraleSelected.idMorale}
                filterSelected={filterSelected}
              />
            )}
          </Fragment>
        </Fragment>
      </div>
    </CompanyContext.Provider>
  );
};

export default DashboardPage;
