import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UniLogo from "../assets/UNI_HR_Logo_RGB_Full_Color.png";
import universeBackground from "./universeBackground.jpg";
import {
  styled,
  Typography,
  Box,
  InputBase,
  Card,
  Button,
  AppBar,
  Toolbar,
} from "@mui/material";

const drawerWidth = 240;

const BtnLogin = styled(Button)({
  color: "#EC5B6A",
  borderColor: "#EC5B6A",
  "&:hover": {
    borderColor: "#EC5B6A",
    backgroundColor: "#EC5B6A",
    color: "#fff",
  },
});

const BtnLogin1 = styled(Button)({
  color: "#ffffff",
  borderColor: "#EC5B6A",
  "&:hover": {
    borderColor: "#EC5B6A",
    backgroundColor: "#EC5B6A",
    color: "#fff",
  },
});

// const StyledRoot = styled("div")({
//   display: "flex",
//   flexDirection: "column",
//   padding: 15,
//   marginTop: 20,
//   margin: "0px auto",
//   minWidth: 350,
//   backgroundColor: "#000000",
//   flexGrow: 1,
// });

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 20,
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  backgroundColor: "#000000",
  flexGrow: 1,
  backgroundImage: `url(${universeBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});

const Outsider = () => {
  const [outsiderName, setOutsiderName] = useState([]);
  const [outsiderEmail, setOutsiderEmail] = useState([]);
  const navigate = useNavigate();

  const handleClickAnswerQuestion = () => {
    navigate("/pages/outsider/questionForOutsider");
  };

  return (
    <>
      <div
        sx={{
          display: "flex",
          marginRight: "40px",
        }}
      >
        <AppBar
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.25)",
            //backgroundColor: "#281074",
            // position: "fixed",
            boxShadow: "none",
            borderRadius: "45px",
            maxWidth: "95%",
            justifyContent: "center",
            marginTop: "50px",
            marginRight: "35px",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Link to="/">
              <div style={{ width: "112px", height: "63px" }}>
                <img
                  alt="uni-culture-logo"
                  src={UniLogo}
                  style={{
                    marginTop: "-17.5px",
                    marginLeft: "-25px",
                    width: "120%",
                    height: "160%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </Link>

            <Box>
              <BtnLogin1
                // variant="outlined"
                component={Link}
                to="/login"
              >
                About Us
              </BtnLogin1>

              <BtnLogin1
                // variant="outlined"
                component={Link}
                to="/login"
              >
                Log In
              </BtnLogin1>
            </Box>
          </Toolbar>
        </AppBar>
      </div>

      <StyledRoot className={"page"} sx={{}}>
        <Typography
          variant="h2"
          sx={{
            color: "white",
            marginTop: "150px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Welcome
        </Typography>

        {/* <div
          style={{
            width: "112px",
            height: "63px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "16px",
          }}
        >
          <img
            alt="UnicornHeart"
            src={UnicornHeart}
            style={{
              // marginTop: "-17.5px",
              // marginLeft: "-25px",
              width: "200%",
              height: "200%",
              objectFit: "contain",
            }}
          />
        </div> */}

        <Box
          sx={{
            marginTop: "16px",
            // borderLeft: "10px solid #EC5B6A",
            width: "400px",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "50px",
            }}
          >
            <Box>
              <Card
                sx={{
                  padding: "8px",
                  borderRadius: "30px",
                  width: "300px",
                  alignItems: "center",
                }}
              >
                <InputBase
                  placeholder="ชื่อ"
                  name="outsiderName"
                  sx={{ width: "250px", paddingLeft: "10px" }}
                  value={outsiderName.value}
                  inputProps={{ maxLength: 200 }}
                />
              </Card>
              <Card
                sx={{
                  padding: "8px",
                  borderRadius: "30px",
                  width: "300px",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <InputBase
                  placeholder="อีเมล"
                  name="outsiderEmail"
                  sx={{ width: "250px", paddingLeft: "10px" }}
                  value={outsiderEmail.value}
                  inputProps={{ maxLength: 200 }}
                />
              </Card>
            </Box>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
                   <Link to="/">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#bd6acf",
                borderRadius: "15px",
                width: "100px",
              }}
              onClick={() => navigate('/pages/outsider/questionForOutisider')}
            >
              ตอบคำถาม
            </Button>
            </Link>
          </div>

        </Box>
      </StyledRoot>
    </>
  );
};

export default Outsider;
