import React from "react";
import Plot from "react-plotly.js";
import { Typography } from "@mui/material";

const DailyGaugeChart = ({ value, size }) => {
  // Function to calculate the needle's angle and position
  const gaugeNeedle = (value) => {
    const degrees = -180 * (value / 100) + 180;
    const radians = (degrees * Math.PI) / 180;
    const radius = 0.3;
    const baseY = 0.3;

    const x = 0.5 + radius * Math.cos(radians);
    const y = baseY + radius * Math.sin(radians);
    return [x, y, baseY];
  };

  const [xNeedle, yNeedle, baseY] = gaugeNeedle(value);

  // Define the pointy triangle for the needle tip
  const needlePath = `M 0.5 ${baseY} L ${xNeedle} ${yNeedle} L ${
    0.5 + 0.02 * Math.cos((Math.PI / 180) * (-180 * (value / 100) + 180))
  } ${
    baseY + 0.02 * Math.sin((Math.PI / 180) * (-180 * (value / 100) + 180))
  } Z`;

  return (
    <div style={{ textAlign: "center", color: "white" }}>
      <Typography component="div" color={"white"}>
        คะแนนเฉลี่ย
      </Typography>
      <Plot
        data={[
          {
            type: "indicator",
            mode: "gauge",
            value: value,
            gauge: {
              axis: {
                range: [0, 100],
                tickcolor: "white",
                tickfont: { color: "white" },
              },
              bar: { color: "white", thickness: 0.2 },
              steps: [
                { range: [0, 20], color: "#ff6347" },
                { range: [20, 40], color: "#ffcc00" },
                { range: [40, 60], color: "ffd700" },
                { range: [60, 80], color: "#87cefa" },
                { range: [80, 100], color: "#4682b4" },
              ],
              // Ensure the gauge is a half circle
              shape: "angular",
              bgcolor: "transparent",
            },
          },
        ]}
        layout={{
          autosize: true,
          width: size?.width || 250,
          height: size?.height || 250,
          paper_bgcolor: "rgba(0,0,0,0)",
          plot_bgcolor: "rgba(0,0,0,0)",
          font: {
            color: "white",
          },
          shapes: [
            // Needle shape aligned with the semi-circle (as a triangle)
            {
              type: "path",
              path: needlePath,
              fillcolor: "white",
              line: {
                color: "white",
                width: 1,
              },
            },

            // Needle center circle
            {
              type: "circle",
              x0: 0.48,
              y0: baseY - 0.02,
              x1: 0.52,
              y1: baseY + 0.02,
              fillcolor: "white",
              line: {
                color: "white",
              },
            },
          ],
          margin: {
            t: 30,
            r: 30,
            l: 30,
            b: 30,
          },
        }}
        config={{
          responsive: true,
          displayModeBar: false,
        }}
      />
    </div>
  );
};

export default DailyGaugeChart;
