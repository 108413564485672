import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UniLogo from "../assets/UNI_HR_Logo_RGB_Full_Color.png";
import UnicornHeart from "../../pages/admin/moraleDashboard/assets/Unicorn-Mascot-Heart-square.png";
import universeBackground from "./universeBackground.jpg";
import {
  CircularProgress,
  Grid,
  styled,
  Typography,
  Box,
  InputBase,
  Card,
  Button,
  AppBar,
  Toolbar,
} from "@mui/material";

const drawerWidth = 240;

const BtnLogin = styled(Button)({
  color: "#EC5B6A",
  borderColor: "#EC5B6A",
  "&:hover": {
    borderColor: "#EC5B6A",
    backgroundColor: "#EC5B6A",
    color: "#fff",
  },
});

const BtnLogin1 = styled(Button)({
  color: "#ffffff",
  borderColor: "#EC5B6A",
  "&:hover": {
    borderColor: "#EC5B6A",
    backgroundColor: "#EC5B6A",
    color: "#fff",
  },
});

// const StyledRoot = styled("div")({
//   display: "flex",
//   flexDirection: "column",
//   padding: 15,
//   marginTop: 20,
//   margin: "0px auto",
//   minWidth: 350,
//   backgroundColor: "#000000",
//   flexGrow: 1,
// });

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 20,
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  backgroundColor: "#000000",
  flexGrow: 1,
  backgroundImage: `url(${universeBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});

const OutsiderAnswer = () => {




  return (
    <>
     <StyledRoot>
        <Typography
          variant="h2"
          sx={{
            color: "white",
            marginTop: "150px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Welcome
        </Typography>

        {/* <div
          style={{
            width: "112px",
            height: "63px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "16px",
          }}
        >
          <img
            alt="UnicornHeart"
            src={UnicornHeart}
            style={{
              // marginTop: "-17.5px",
              // marginLeft: "-25px",
              width: "200%",
              height: "200%",
              objectFit: "contain",
            }}
          />
        </div> */}

</StyledRoot>
    </>
  );
};

export default OutsiderAnswer;
