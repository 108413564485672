import React, { useEffect } from "react";
import { useNavigate,  } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Typography,
  Card,
  CardContent,
  Divider,
  Chip,
  LinearProgress,
  Stack,
  Button,
  Container,
  Grid,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";

import NotificationIcon from "./assets/notification.svg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HeaderPage from "../../../shared/header/headerPage";
import * as dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllMoraleDaily } from "../../../../../actions/moraleDaily";
import { getAllQuestionTopic } from "../../../../../actions/questionTopic";
import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";

const WrapHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: '24px',
  paddingRight: '24px'
});

const WrapSection2 = styled("div")({
  width: "100%",
});

const WrapSection3 = styled("div")({
  marginTop: 30,
  width: "100%",
});

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const StyledCard = styled(Card)({
  // height: '520px',
  height: '100%',
  width:'100%',
  display: "flex",
  flexDirection: "column",
  border: '10px solid ', 
});

const WrapCardHeaderInsight = styled("div")({
  display: "flex",
  padding: 5,
  width: "70%",
  borderTopRightRadius: 50,
  borderBottomRightRadius: 50,
  justifyContent: "space-between",
  alignItems: "center",
});

const WrapCardHeaderInsight2 = styled("div")({
  display: "flex",
  padding: 5,
  width: "28%",
  borderTopLeftRadius: 50,
  borderBottomLeftRadius: 50,
  alignItems: "center",
  backgroundColor: "#EFEFEF",
  marginLeft: "auto",
});

const WrapCardHeaderInsightImage = styled("div")({
  backgroundColor: "white",
  borderRadius: "50%",
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledCardHeaderInsightIcon = styled(Avatar)({
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFD923",
  color: "#EA8900",
});

const StyledDivider = styled(Divider)({
  width: "100%",
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
});

const colorBGCardHeader = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  const idModTen = id % 10
  return colorBGCardHeader[idModTen]
}

const MoraleDaily = (props) => {
  const navigate = useNavigate();
  const [insightList, setInsightList] = React.useState([]);

  const handleSelectInsight = (id) => {
    if (id === 0) {
      setInsightList([]);
    } else {
      if (insightList.some((value) => value === id)) {
        setInsightList(insightList.filter((value) => value !== id));
      } else {
        setInsightList([...insightList, id]);
      }
    }
  };

  const dispatch = useDispatch();
  const { result: moraleDaily } = useSelector((state) => state.moraleDaily);
  const { result: questionTopic } = useSelector((state) => state.questionTopic);

  useEffect(() => {
    dispatch(getAllMoraleDaily());
    dispatch(getAllQuestionTopic());
  }, []);

  const imgInsight = (id) => {
    const idModTen = id % 10
    switch (idModTen) {
      case 0:
        return H1;
      case 1:
        return H2;
      case 2:
        return H3;
      case 3:
        return H4;
      case 4:
        return H5;
      case 5:
        return H6;
      case 6:
        return H7;
      case 7:
        return H8;
      case 8:
        return H9;
      case 9:
        return H10;
      default:
        break;
    }
  };

  if (!Array.isArray(moraleDaily) || moraleDaily.length === 0) {
    return (
      <StyledRoot className={"page"}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh', // Full height of the viewport
          }}
        >
          <CircularProgress /> 
        </Box>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => navigate(-1)} aria-label="go back">
              <ArrowBackIosIcon />
          </IconButton>
          <HeaderPage textLabel={"Morale Daily"} icon={NotificationIcon} />
        </Box>
          
        <div className={`wrap-button-add`}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/admin/manage/notification`}
          >
            ส่งแจ้งเตือนคำถาม
          </Button>
        </div>
      </WrapHeader>
      <StyledDivider />
      <Container sx={{ 
        marginLeft: 1, 
        maxWidth: { xs: '100%', sm: '80%', md: '70%', lg: '100%' }, // Adjust maxWidth for different screen sizes
        padding: 2,
      }}>
        {questionTopic && (
          <div style={{ marginBottom: "24px" }}>
            <Typography gutterBottom variant="h6">
              หัวข้อคำถาม
            </Typography>
            <StyledChip
              clickable
              label={"ALL"}
              color={insightList.length === 0 ? "primary" : "default"}
              onClick={() => handleSelectInsight(0)}
            />
            {questionTopic.slice(1).map((data, index) => (
              <StyledChip
                key={index}
                clickable
                label={data.questionTopic}
                color={insightList.includes(data.idQuestionTopic) ? "primary" : "default"}
                onClick={() => handleSelectInsight(data.idQuestionTopic)}
              />
            ))}
          </div>
        )}
        <Grid container spacing={2}
          sx={{
            display:'flex', 
            alignItems: 'center'
          }}>
          {moraleDaily &&
            moraleDaily
              .filter((data) => {
                return insightList.length > 0
                  ? insightList.includes(data.idQuestionTopic)
                  : data;
              })
              .map((data) => {
                const numberOfAnswer = data.participantList.filter(
                  (item) => item.answer !== null
                ).length;
                return (
                  <Grid item xs={12} sm={12} lg={4} xl={3} sx={{                
                    display:'flex',
                    justifyContent:'flex-start',
                  }}>
                    <StyledCard elevation={1} key={data.idMoraleDaily} sx={{ position: 'relative', borderRadius: '15px',borderColor: getBgCard(data.idQuestionTopic), padding: 2 }}>
                      <div
                        style={{
                          position: 'absolute', 
                          top: 0, 
                          left: 0, 
                          backgroundColor: getBgCard(data.idQuestionTopic), 
                          width: 45, 
                          height: 45, 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'center'
                        }}>
                      </div>
                      <div style={{
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        backgroundColor: "#FFD923", 
                        color: "#EA8900",
                        borderRadius: '50%', 
                        width: 40, 
                        height: 40, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center'
                      }}>
                        <Typography style={{ fontSize:'40px', color: "#EA8900", fontWeight:'600' }}>{data.reward}</Typography>
                      </div>

                      <Container style={{height:270, alignItems: 'center',}}>
                        <Stack display='flex' direction="column" alignItems="center" spacing={1} >
                          <div style={{  alignContent:'center', paddingTop:25, paddingBottom:10}}>
                            <Typography variant="h6" color="primary" textAlign={"center"} sx={{ lineHeight: 1, fontWeight: 'bold', color: getBgCard(data.idQuestionTopic), fontSize: '28px', textAlign:'center'}} >
                              {data.questionTopic}
                            </Typography>
                          </div>
                          <img
                            alt="questionImg"
                            width={140}
                            height={170}
                            src={imgInsight(data.idQuestionTopic)}
                            style={{ borderRadius: '50%', backgroundColor: '#FFF', }}
                          />
                        </Stack>
                      </Container>

                      <Container  style={{height:150, 
                      //border: `3px solid ${getBgCard(data.idQuestionTopic)}`,
                      borderRadius: '4px', paddingTop:5, 
                      background: `${getBgCard(data.idQuestionTopic)}50`}} >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start',alignContent:'start', }}>
                          <Typography variant="subtitle1" color="textSecondary"textAlign={"left"}>
                            {`คำถาม:`}
                          </Typography>
                          <Typography variant="h5" textAlign={"left"} sx={{ lineHeight: 1.1, fontSize: '18px', alignContent:'start', marginBottom:1 }}>
                            {data.question}
                          </Typography>
                        
                        </div>
                      </Container>
                      <Container  style={{height:80, }} >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start',alignContent:'start', }}>
                          <Typography variant="subtitle1" color="textSecondary" style={{ textAlign: "left", marginBottom:'-7px', fontSize:14  }}>
                            {`ประเภทคำตอบ: ${data.questionType === "text" ? "ข้อความ" : "ตัวเลือก"}`}
                          </Typography>
                          <Typography style={{ textAlign: "left", marginBottom:'-7px'  }} variant="subtitle1">
                            {`start: ${dayjs(data.startDate).format("YYYY-MM-DD HH:mm")}`}
                          </Typography>
                          <Typography style={{ textAlign: "left" }} variant="subtitle1">
                            {`end: ${dayjs(data.endDate).format("YYYY-MM-DD HH:mm")}`}
                          </Typography>
                        </div>
                      </Container>
    
                      <div style={{ paddingTop: "25px", paddingLeft:'10px', paddingRight:'10px' }}>
                        <LinearProgress
                          style={{width:'100%', height:'10px'}}
                          variant="determinate"
                          value={(numberOfAnswer * 100) / data.participantList.length}
                        />
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="body2" color={"#3C3C3C"}>
                            {`ประเมินแล้ว ${numberOfAnswer}/${data.participantList.length} คน`}
                          </Typography>
                          <Button
                            component={Link}
                            to={`/admin/manage/moraleDaily/${data.idMoraleDaily}`}
                          >
                            ดูคำตอบ
                          </Button>
                        </Stack>
                      </div>
                    </StyledCard>
                  </Grid>
                );
              })}
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default MoraleDaily;
