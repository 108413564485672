import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
});

httpClient.interceptors.request.use(async (config) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    config.headers = {
      "authorization":  `Bearer ${user.accessToken}`,
      "x-access-token": user.accessTokenUni,
    }
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      window.location.assign("/login");
      localStorage.removeItem("user");
    }
    //   const token = result.data.jwt;
    //   localStorage.setItem(server.TOKEN_KEY, token);
    //   debugger;
    //   return axios.request(error.config);
    // }
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      //alert(JSON.stringify(error));
      return Promise.reject({
        code: "NOT_CONNECT_NETWORK",
        message: "Cannot connect to server, Please try again.",
      });
    }

    return Promise.reject(error.response);
  }
);

export { httpClient }