import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { SpecialIcon } from "../../shared/typeCoin";
import InputField from "../../shared/inputForm/inputField";
import { useCoinType, useUpdateCoinBudget } from "../../../../quries/coin";
import { toast } from "sonner";
import utils from "../../../../utils";

const schema = yup.object().shape({
  coinType: yup.object().nullable().required("Required"),
  budget: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer()
    .min(1)
    .nullable()
    .required("Required"),
  totalCoin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer()
    .min(1)
    .nullable()
    .required("Required"),
});

const BudgetCoinEdit = ({ open, handleClose, companySelected }) => {
  const updateCoinBudget = useUpdateCoinBudget({
    idCompany: companySelected?.idCompany,
  });

  const coinType = useCoinType({
    idCompany: companySelected?.idCompany,
  });
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      coinType: null,
      budget: "",
      totalCoin: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    updateCoinBudget.mutate(
      {
        ...values,
        idCoinType: values.coinType.idCoinType,
      },
      {
        onSuccess: () => toast.success("สำเร็จ"),
        onError: () => toast.error("เกิดข้อผิดพลาด"),
        onSettled: () => handleClose(),
      }
    );
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>ตั้งค่าเหรียญ</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
            <Controller
              control={control}
              name={"coinType"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  sx={{ minWidth: 200 }}
                  options={coinType.data}
                  onChange={(event, value) => field.onChange(value)}
                  isOptionEqualToValue={(option, value) =>
                    option.idCoinType === value.idCoinType
                  }
                  getOptionLabel={(option) => option.coinName}
                  renderOption={(props, option) => {
                    return (
                      <Box
                        {...props}
                        component="li"
                        sx={{ display: "flex", gap: "1rem" }}
                      >
                        <SpecialIcon
                          idCoinType={option.idCoinType}
                          width={40}
                        />
                        <Typography>{option.coinName}</Typography>
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState?.error?.message}
                      label="Coin"
                    />
                  )}
                />
              )}
            />
            <InputField control={control} name={"budget"} label={"Budget"} />
            <InputField
              control={control}
              name={"totalCoin"}
              label={"จำนวนเหรียญ"}
            />
          </div>
          <Typography variant="h6">
            {`มูลค่าต่อเหรียญ: ${
              watch("totalCoin")
                ? utils.numberWithCommas(watch("budget") / watch("totalCoin"))
                : 0
            }`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button type="submit" variant="contained">
            ยืนยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BudgetCoinEdit;
