import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Typography,
  Card,
  Divider,
  Stack,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Box,
  Container,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import NotificationIcon from "./assets/notification.svg";
import HeaderPage from "../../../shared/header/headerPage";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getMoraleDaily } from "../../../../../actions/moraleDaily";

import { getAllEmployees } from "../../../../../actions/user";
import * as dayjs from "dayjs";
import * as XLSX from "xlsx";
import FeelingIcon from "../../../shared/feelingIcon";

import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";
import zIndex from "@mui/material/styles/zIndex";

const WrapHeader = styled("div")({
  display: "flex",
  width: "100%",
});

const WrapSection2 = styled("div")({});

const WrapSection3 = styled("div")({
  marginTop: 15,
});

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#6347FF",
  color: "white",
  borderRadius: 15,
  position: "relative",
  width: "auto",
  padding: 40,
}));

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-root": {
      border: "1px solid #ccc", // Border around the DataGrid
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "1px solid #fff", // Border between rows
    },
    "& .MuiDataGrid-columnHeader": {
      borderBottom: "2px solid #ccc",
      "&:hover": "none",
    },
  },
  evenRow: {
    backgroundColor: "#fff",
  },
  oddRow: {
    backgroundColor: "#eaecff",
  },
}));

const MoraleDailyAnswer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { result: moraleDailyItem } = useSelector(
    (state) => state.moraleDailyItem
  );
  const [mode, setMode] = React.useState("average");

  useEffect(() => {
    dispatch(getMoraleDaily(id));
  }, [dispatch, id]);

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0
      ? classes.evenRow
      : classes.oddRow;
  };

  const handleChangeMode = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const imgInsight = (id) => {
    const idModTen = id % 10;
    switch (idModTen) {
      case 0:
        return H1;
      case 1:
        return H2;
      case 2:
        return H3;
      case 3:
        return H4;
      case 4:
        return H5;
      case 5:
        return H6;
      case 6:
        return H7;
      case 7:
        return H8;
      case 8:
        return H9;
      case 9:
        return H10;
      default:
        break;
    }
  };

  const answerQuestions6Scale = [
    {
      key: "verybad",
      label: "Very Bad",
      value: "0",
    },
    {
      key: "bad",
      label: "Bad",
      value: "20",
    },
    {
      key: "littlebad",
      label: "Little Bad",
      value: "40",
    },
    {
      key: "littlehappy",
      label: "Little Happy",
      value: "60",
    },
    {
      key: "happy",
      label: "Happy",
      value: "80",
    },
    {
      key: "veryhappy",
      label: "Very Happy",
      value: "100",
    },
  ];

  const calPercentScale = (answerType) => {
    const count = moraleDailyItem.participantList.reduce(
      (counter, obj) => (obj.answer === answerType ? (counter += 1) : counter),
      0
    );
    return count;
  };

  const calNumOfAnswer = () => {
    const count = moraleDailyItem.participantList.reduce(
      (counter, obj) => (obj.answer ? (counter += 1) : counter),
      0
    );
    return count;
  };

  const calScore = () => {
    if (mode === "average") {
      const score = moraleDailyItem.participantList.reduce((counter, obj) => {
        switch (obj.answer) {
          case "Very Bad":
            counter += 0;
            break;
          case "Bad":
            counter += 20;
            break;
          case "Little Bad":
            counter += 40;
            break;
          case "Little Happy":
            counter += 60;
            break;
          case "Happy":
            counter += 80;
            break;
          case "Very Happy":
            counter += 100;
            break;
          default:
            break;
        }
        return counter;
      }, 0);
      const average = score / calNumOfAnswer();
      return average;
    } else {
      let count = 0
      const score = moraleDailyItem.participantList.reduce(
        (counter, obj) => { 
          switch (obj.answer) {
            case "Happy":
              counter += 80;
              count++;
              break;
            case "Very Happy":
              counter += 100;
              count++;
              break;
            default:
              break;
          }
          return counter;
        },
        0
      );
      // console.log(score)
      // console.log(count)
      const bestratio = score / count;
      return bestratio;
    }
  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => <Avatar src={params.row.imageURL} />,
      headerAlign: "center",
    },
    {
      field: "employeeID",
      headerName: "employee ID",
      width: 150,
      sortable: false,
    },
    {
      field: "firstname_TH",
      headerName: "First name",
      width: 150,
      sortable: false,
    },
    {
      field: "lastname_TH",
      headerName: "Last name",
      width: 150,
      sortable: false,
    },
    {
      field: "divisionName",
      headerName: "Division",
      sortable: false,
      width: 100,
    },
    {
      field: "departmentName",
      headerName: "Department",
      sortable: false,
      width: 120,
    },
    {
      field: "sectionName",
      headerName: "Section",
      sortable: false,
      width: 100,
    },
    {
      field: "positionName",
      headerName: "Position",
      sortable: false,
      width: 120,
    },
    {
      field: "answer",
      headerName: "Answer",
      sortable: false,
      width: 100,
    },
  ];

  const rows = () => {
    return moraleDailyItem.participantList.map((item) => {
      return {
        id: item.idEmployees,
        employeeID: item.employeeID,
        imageURL: item.imageURL,
        firstname_TH: item.firstname_TH,
        lastname_TH: item.lastname_TH,
        divisionName: item.divisionName || "-",
        departmentName: item.departmentName || "-",
        sectionName: item.sectionName || "-",
        positionName: item.positionName || "-",
        answer: item.answer || "ไม่มีข้อมูล",
      };
    });
  };

  const exportToExcel = () => {
    const data = rows().map(({ imageURL, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
    const formattedDate = dayjs(moraleDailyItem.startDate).format("DD-MM-YYYY");
    const filename = `Morale Daily Answer (${formattedDate}).xlsx`;
    XLSX.writeFile(workbook, filename);
  };

  return (
    <StyledRoot className={"page"}>
      <WrapHeader>
        <IconButton onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosIcon />
        </IconButton>
        <HeaderPage textLabel={"Morale Daily Answer"} icon={NotificationIcon} />
      </WrapHeader>
      <StyledDivider />

      {moraleDailyItem ? (
        <Container
          sx={{ maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "100%" } }}
        >
          <Grid container marginBottom={1} position="relative">
            <Grid
              item
              xs={12}
              lg={12}
              display="flex"
              justifyContent={{ xs: "center", lg: "flex-end" }}
              alignItems="center"
              sx={{
                position: { md: "absolute" }, // Make it overlap for large screens
                right: { md: 40, lg: 20 },
                top: { md: -10, lg: -40 }, // Adjust image position for overlap
                zIndex: 2,
              }}
            >
              <Box style={{ width: { xs: "60%", sm: "50%", lg: "30%" } }}>
                <Stack direction="column" alignItems="flex-end">
                  <img
                    alt="questionImg"
                    // width={340}
                    //height={380}
                    width="25%"
                    height="25%"
                    src={imgInsight(moraleDailyItem.idQuestionTopic)}
                  />
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <StyledCard>
                <Grid container marginBottom={1}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        marginBottom: 3,
                        color: "white",
                        fontSize: 32,
                        width: "75%",
                      }}
                    >
                      {moraleDailyItem.question}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} marginBottom={1} width={"90%"}>
                  <Grid item sm={12} md={8} lg={4}>
                    <Box sx={{ width: "100%" }}>
                      <Typography color="#D9D9D9" marginBottom={1}>
                        คำถามรายวันประจำวันที่:
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: "#F9C22D",
                          padding: 3,
                          borderRadius: 5,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            color: "#fff",
                            fontSize: 24,
                          }}
                        >
                          {dayjs(moraleDailyItem.startDate).format(
                            "DD MMMM YYYY"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={8} lg={4}>
                    <Box sx={{ width: "100%" }}>
                      <Typography color="#D9D9D9" marginBottom={1}>
                        หัวข้อคำถาม:
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: "#F9C22D",
                          padding: 3,
                          borderRadius: 5,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            color: "#fff",
                            fontSize: 24,
                          }}
                        >
                          {moraleDailyItem.questionTopic}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={8} lg={2}>
                    <Box sx={{ width: "100%" }}>
                      <Typography color="#D9D9D9" marginBottom={1}>
                        ประเภทคำตอบ:
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: "#F9C22D",
                          padding: 3,
                          borderRadius: 5,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            color: "#fff",
                            fontSize: 24,
                          }}
                        >
                          {moraleDailyItem.questionType}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
          <WrapSection2>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Grid container justifyContent={"center"} width="45%">
                {answerQuestions6Scale.map((item) => (
                  <Grid item xs={6} md={4} lg={2} key={item.key}>
                    <Stack
                      direction={"column"}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <div style={{ width: 70, height: 70 }}>
                        <FeelingIcon feeling={item.key} unSelected={true} />
                      </div>
                      <div>
                        <Typography sx={{ fontSize: 14 }}>
                          {item.label}
                        </Typography>
                        <Typography align="center">
                          {`${
                            (calPercentScale(item.label) * 100) /
                            calNumOfAnswer()
                              ? Math.round(
                                  (calPercentScale(item.label) * 100) /
                                    calNumOfAnswer()
                                )
                              : 0
                          }%`}
                        </Typography>
                      </div>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: -6,
              }}
            >
              <Typography color={"#3C3C3C"} marginRight={2}>
                {`ประเมินแล้ว ${calNumOfAnswer()}/${
                  moraleDailyItem.participantList.length
                } คน`}
              </Typography>
              <Typography color={"#3C3C3C"} marginRight={2}>
                {`Score: ${Math.round(calScore())}`}
              </Typography>
              <ToggleButtonGroup
                color="secondary"
                value={mode}
                exclusive
                onChange={handleChangeMode}
                aria-label="Platform"
              >
                <ToggleButton value="average">Average</ToggleButton>
                <ToggleButton value="bestratio">best ratio</ToggleButton>
              </ToggleButtonGroup>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileDownloadIcon />}
                onClick={exportToExcel}
                sx={{
                  marginLeft: 2,
                  height: 47.5,
                  backgroundColor: "#6347FF",
                  ":hover": {
                    backgroundColor: "#3e21e1",
                  },
                }}
              >
                ดาวน์โหลด
              </Button>
            </Box>
          </WrapSection2>
          <WrapSection3>
            <div className={classes.root}>
              <DataGrid
                autoHeight
                rows={rows()}
                columns={columns}
                pageSize={12}
                rowsPerPageOptions={[12]}
                getRowClassName={getRowClassName}
                sx={{
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#6347FF",
                    "& .MuiDataGrid-cell": {
                      color: "#fff", // Change text color on hover
                    },
                  },
                }}
                disableRowSelectionOnClick
                // experimentalFeatures={{ newEditingApi: true }}
              />
            </div>
          </WrapSection3>
        </Container>
      ) : (
        <Typography variant="h6" color="textSecondary">
          ไม่พบข้อมูล
        </Typography>
      )}
    </StyledRoot>
  );
};

export default MoraleDailyAnswer;
