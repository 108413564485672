import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import "./index.css";
import {
  Divider,
  Grid,
  Typography,
  Stack,
  Card,
  CardContent,
  Box,
  Container,
} from "@mui/material";
import dayjs from "dayjs";
import { SpecialIcon } from "../../shared/typeCoin";
import utils from "../../../../utils";
import {
  useCoinInReward,
  useCoinOutActivity,
  useTokenomic,
} from "../../../../quries/coin";
import { useCoinType } from "../../../../quries/coin";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import TableTransaction from "./tableTransaction";
import LoadingIcon from "../../shared/general/loadingIcon";
import SelectAffiliateCompanyDark from "../../shared/general/selectAffiliateCompanyDark";

const StyledTextCoinType = styled(Typography)({
  color: "whitesmoke",
  textAlign: "center",
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 10,
  backgroundColor: "#ffffff2e",
});

const WrapAllCoinFilter = styled("div")({
  backgroundColor: "#ffffffb3",
  borderRadius: "50%",
  width: 200,
  height: 200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledCardContentHeart = styled(CardContent)({
  padding: "0 !important",
  backgroundColor: "#cf483d",
});

const WrapCardTodayCount = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: 16,
});

const WrapCardYearCountHeart = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  padding: 8,
  marginTop: 8,
  position: "relative",
  backgroundColor: "#aa291f",
});

const WrapAllCoin = styled("div")({
  // display: "block",
  margin: "0px auto",
  width: "100%",
  ["@media only screen and (min-width:1024px)"]: {
    width: "80%",
  },
});

const StyledCard = styled(Card)({
  width: "100%",
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "0px auto",
});

const color = ["#FFB4AC", "#264E70", "#679186", "#F6BA6F", "#6DA9E4"];

const AllSpecialCoin = ({ idCoinType, coinName }) => {
  const tokenomic = useTokenomic({
    select: (data) => data.find((item) => item.idCoinType === idCoinType),
  });

  const coinInReward = useCoinInReward({ idCoinType });
  const coinOutActivity = useCoinOutActivity({ idCoinType });

  if (coinInReward.isPending || coinOutActivity.isPending) {
    return <LoadingIcon />;
  }

  const specialCoinYearIn = coinInReward.data.reduce(
    (prev, curr) => curr.coin + prev,
    0
  );
  const specialCoinYearOut = coinOutActivity.data.reduce(
    (prev, curr) => curr.coin + prev,
    0
  );

  return (
    <WrapAllCoin>
      <Grid container alignItems={"center"} marginTop={6} spacing={10}>
        <Grid item xs={12} md={5} lg={4}>
          <Stack alignItems={"center"} spacing={2}>
            <Typography
              variant="h4"
              style={{ lineHeight: 1, color: "#ffffff" }}
              textAlign={"center"}
            >
              {coinName.toUpperCase()}
            </Typography>
            <SpecialIcon idCoinType={idCoinType} width={140} />
            <Typography
              variant="h5"
              style={{ lineHeight: 1, color: "#ffffff" }}
            >
              {`มูลค่า ${
                tokenomic?.value && tokenomic?.amount
                  ? utils.numberWithCommas(
                      (tokenomic.value / tokenomic.amount).toFixed(2)
                    )
                  : 0
              }฿`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <StyledCard>
            <StyledCardContentHeart
              style={{ backgroundColor: color[idCoinType % 5] }}
            >
              <WrapCardTodayCount>
                <div style={{ width: "100%" }}>
                  <Typography
                    variant="h2"
                    align="center"
                    style={{ lineHeight: 1, color: "#ffffff" }}
                  >
                    {utils.numberWithCommas(specialCoinYearOut)}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ lineHeight: 1, color: "#ffffff" }}
                  >
                    แจกออก
                  </Typography>
                </div>
                <Divider orientation="vertical" flexItem>
                  <div>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 12,
                        color: "#ffffffb0",
                        paddingBottom: 2,
                      }}
                    >
                      ALL YEAR
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        lineHeight: 1,
                        fontSize: 16,
                        color: "#ffffffb0",
                      }}
                    >
                      {" "}
                      {dayjs().format("YYYY")}{" "}
                    </Typography>
                  </div>
                </Divider>
                <div style={{ width: "100%" }}>
                  <Typography
                    variant="h2"
                    align="center"
                    style={{ lineHeight: 1, color: "#ffffff" }}
                  >
                    {utils.numberWithCommas(specialCoinYearIn)}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ lineHeight: 1, color: "#ffffff" }}
                  >
                    แลกคืน
                  </Typography>
                </div>
              </WrapCardTodayCount>
              <WrapCardYearCountHeart>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ lineHeight: 1, color: "#ffffff" }}
                >
                  ยังไม่ได้แลก
                </Typography>
                <Typography
                  marginLeft={2}
                  variant="h6"
                  align="center"
                  style={{ lineHeight: 1, color: "#ffffff" }}
                >
                  {utils.numberWithCommas(
                    specialCoinYearOut - specialCoinYearIn
                  )}
                </Typography>
              </WrapCardYearCountHeart>
            </StyledCardContentHeart>
          </StyledCard>
        </Grid>
      </Grid>
    </WrapAllCoin>
  );
};

export default function SpecialCoin() {
  const [companySelected, setCompanySelected] = useState(null);
  const [currentCoin, setCurrentCoin] = useState(0);

  const { data: coinType, isPending } = useCoinType({
    idCompany: companySelected?.idCompany,
    select: (data) => data.filter((item) => item.coinType === null),
  });

  const onCardChange = (event) => {
    setCurrentCoin(event.realIndex);
  };

  return (
    <StyledRoot className={"page dashboard-page"}>
      <Typography variant="h4" color={"#ffffff"} marginTop={2}>
        สรุปเหรียญพิเศษ
      </Typography>
      <StyledDivider />
      <div style={{ maxWidth: 320, width: "100%", marginBottom: 16 }}>
        <SelectAffiliateCompanyDark
          value={companySelected}
          onChange={setCompanySelected}
          defaultSelect={true}
        />
      </div>
      {isPending ? (
        <LoadingIcon />
      ) : (
        <Container>
          {coinType.length > 0 ? (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div style={{ display: "flex" }}>
                  <Box height={200} width={"100%"}>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                      onRealIndexChange={onCardChange}
                    >
                      <SwiperSlide>
                        <WrapAllCoinFilter>
                          <Typography
                            variant="h2"
                            style={{
                              color: "#636363ee",
                              fontSize: 76,
                              fontWeight: 400,
                            }}
                          >
                            ALL
                          </Typography>
                        </WrapAllCoinFilter>
                      </SwiperSlide>
                      {coinType.map((item) => (
                        <SwiperSlide>
                          <SpecialIcon idCoinType={item.idCoinType} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                </div>
              </Grid>
              {currentCoin === 0 ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <div style={{ marginTop: 24, marginBottom: 24 }}>
                      <StyledTextCoinType variant="h3">
                        ALL SPECIAL COIN
                      </StyledTextCoinType>
                    </div>
                  </Grid>
                  {coinType.map((item) => (
                    <Grid item xs={12}>
                      <AllSpecialCoin
                        idCoinType={item.idCoinType}
                        coinName={item.coinName}
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <TableTransaction coinType={coinType[currentCoin - 1]} />
              )}
            </Grid>
          ) : (
            <Typography variant="h5" color={"#ffffff"} textAlign={"center"}>
              {"ไม่มีข้อมูล"}
            </Typography>
          )}
        </Container>
      )}
    </StyledRoot>
  );
}
