import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/user";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoadingIcon from "../../../shared/general/loadingIcon";

const validationSchema = yup.object({
  type: yup.string().required(),
  value: yup.number().integer().min(1).required(),
  selectedPerson: yup.array().min(1, "โปรดเลือกพนักงาน"),
});

const DialogEditQuota = ({ open, handleClose, handleEdit, title }) => {
  const { result: users } = useSelector((state) => state.users);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      value: 0,
      type: "quota",
      selectedPerson: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const handleEditQuota = (data) => {
    const idEmployeeList = data.selectedPerson.map((item) => item.idEmployees);
    handleEdit({ ...data, selectedPerson: idEmployeeList });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      {users && users.length === 0 ? (
        <LoadingIcon />
      ) : (
        <form onSubmit={handleSubmit(handleEditQuota)}>
          <DialogContent>
            <Stack gap={2} marginTop={1}>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>ตัวเลือก</FormLabel>
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="quota"
                        control={<Radio />}
                        label="โควต้า"
                      />
                      <FormControlLabel
                        value="balance"
                        control={<Radio />}
                        label="คงเหลือ"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="value"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="จำนวน"
                    error={Boolean(fieldState.error)}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="selectedPerson"
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={users}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.idEmployees}>
                          {`${option.firstname_TH} ${option.lastname_TH}`}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="เลือกพนักงาน"
                        error={Boolean(fieldState.error)}
                        helperText={fieldState?.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleClose}>
              ยกเลิก
            </Button>
            <Button type="submit" variant="contained">
              ยืนยัน
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default DialogEditQuota;
