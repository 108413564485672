import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import answerIcon from "./assets/answer.png";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionDailyAnswerText } from "../../../../actions/moraleDaily";

const DailyMoraleQuestions = ({ idCompany, year, month }) => {
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState({});
  const [allExpanded, setAllExpanded] = useState(false);
  const { result: moraleDailyQuestion } = useSelector(
    (state) => state.moraleDailyQuestion
  );

  useEffect(() => {
    if (idCompany && year && month) {
      dispatch(getQuestionDailyAnswerText({ idCompany, year, month }));
    }
  }, [idCompany, year, month]);

  useEffect(() => {
    if (moraleDailyQuestion) {
      setAnswers(moraleDailyQuestion);
    }
  }, [moraleDailyQuestion]);

  const handleTogglePanel = (id) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleToggleAll = () => {
    const newState = !allExpanded;
    setAllExpanded(newState);
    setExpandedPanels(
      newState
        ? answers.reduce((acc, item) => {
            acc[item.idMoraleDaily] = true;
            return acc;
          }, {})
        : {}
    );
  };

  if (!answers || answers.length === 0) {
    return <Typography>ไม่พบข้อมูล</Typography>;
  }

  return (
    <Grid container direction="column" sx={{ width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <img
            src={answerIcon}
            alt="Table Icon"
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
          <Typography variant="h5" sx={{ color: "white", textAlign: "left" }}>
            คำตอบแบบข้อความ
          </Typography>
        </Stack>
        <Button variant="contained" onClick={handleToggleAll}>
          {allExpanded ? "ซ่อน" : "ดูทั้งหมด"}
        </Button>
      </Stack>

      {answers.map((item, index) => (
        <Accordion
          key={item.idMoraleDaily}
          expanded={!!expandedPanels[item.idMoraleDaily]}
          onChange={() => handleTogglePanel(item.idMoraleDaily)}
          sx={{
            borderRadius:
              index === 0
                ? "8px 8px 0 0"
                : index === answers.length - 1
                ? "0 0 8px 8px"
                : "0",
            "&:before": {
              display: "none",
            },
            margin: 0,
            boxShadow: "none",
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: "#919eab29",
              "&:before": {
                border: "none !important",
              },
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${item.idMoraleDaily}-content`}
            id={`panel-${item.idMoraleDaily}-header`}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography>{item.question}</Typography>
              <Chip
                label={
                  item.employeesAnswer && item.employeesAnswer.length > 0
                    ? "มีการตอบกลับ"
                    : "ไม่มีการตอบกลับ"
                }
                color={
                  item.employeesAnswer && item.employeesAnswer.length > 0
                    ? "success"
                    : "default"
                }
              />
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {item.employeesAnswer && item.employeesAnswer.length > 0 ? (
              <>
                <Typography>คำตอบ: </Typography>
                {item.employeesAnswer.map((emp, idx) => (
                  <Grid container key={idx} alignItems="center" sx={{ pb: 1 }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Chip
                        label={`${emp.firstname_TH} ${emp.lastname_TH}`}
                        color="default"
                      />
                      <Chip
                        label={`คะแนนความรู้สึก: ${emp.sentimentScore} %`}
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      {emp.answer}
                    </Typography>
                  </Grid>
                ))}
              </>
            ) : (
              <Chip label="ไม่มีการตอบกลับ" color="default" />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default DailyMoraleQuestions;
