import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Popper, TextField, Typography, styled } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { getAllEmployees } from "../../../../actions/user";

//Translator TH-EN
// import { useTranslation } from "react-i18next";

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.positionName,
});

const filterOptionsName = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.employeeID,
});

const filterOptionsEN = createFilterOptions({
  stringify: (option) =>
    option.firstname_EN + option.lastname_EN + option.positionName_EN,
});

const filterOptionsNameEN = createFilterOptions({
  stringify: (option) =>
    option.firstname_EN + option.lastname_EN + option.employeeID,
});

const SelectEmployees = ({
  handleChange,
  position,
  mode,
  value,
  selectedCompany,
  isShowTerminate,
  multiple,
  style,
}) => {
  //   const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.users);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  //   const engLanguage = i18n.resolvedLanguage === "en";
  //   const filterOption = () => {
  //     if (position) {
  //       if (engLanguage) {
  //         return filterOptionsEN;
  //       } else {
  //         return filterOptions;
  //       }
  //     } else {
  //       if (engLanguage) {
  //         return filterOptionsNameEN;
  //       } else {
  //         return filterOptionsName;
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     if (selectedCompany) {
  //       dispatch(getAllEmployees(mode ? mode : false, selectedCompany.idCompany));
  //     } else {
  //       dispatch(getAllEmployees(mode ? mode : false));
  //     }
  //     handleChange(null);
  //   }, [selectedCompany]);

  useEffect(() => {
    dispatch(getAllEmployees(mode ? mode : false));
  }, []);

  //   let renderOption = () => {
  //     let result = [];
  //     employees.forEach((item) => {
  //       if (isShowTerminate || !item.isTerminate) {
  //         if (userProfile.isFillIn === 1) {
  //           if (item.idDepartment === userProfile.idDepartment) {
  //             result.push({
  //               ...item,
  //               statusTerminate: item.isTerminate
  //                 ? `${t("AppMenu.Employees")}${t("EmployeeOut")}`
  //                 : `${t("AppMenu.Employees")}${t("Normal")}`,
  //             });
  //           }
  //         } else {
  //           result.push({
  //             ...item,
  //             statusTerminate: item.isTerminate
  //               ? `${t("AppMenu.Employees")}${t("EmployeeOut")}`
  //               : `${t("AppMenu.Employees")}${t("Normal")}`,
  //           });
  //         }
  //       }
  //     });

  //     if (!isShowTerminate) {
  //       result = result.sort((a, b) =>
  //         a.positionName.localeCompare(b.positionName)
  //       );
  //     } else {
  //       result = result.sort((a, b) =>
  //         a.statusTerminate.localeCompare(b.statusTerminate)
  //       );
  //     }

  //     return result;
  //   };

  return (
    <>
      {employees && userProfile && (
        // <StyledBoxSearch>
        //   <Typography className="label" color="text.third">
        //     ค้นหาพนักงาน
        //   </Typography>
          <StyledAutocomplete
            sx={style}
            multiple={multiple}
            options={employees.filter(
              (user) => user.idEmployees !== userProfile.idEmployee
            )}
            value={value}
            onChange={(_, newValue) => handleChange(newValue)}
            // filterOptions={filterOption()}
            groupBy={
              isShowTerminate
                ? (option) => option.statusTerminate
                : position
                ? (option) => option.positionName
                : null
            }
            popupIcon={<i classIcon="fa-light fa-chevron-down"></i>}
            getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
            // getOptionLabel={(option) =>
            //   engLanguage
            //     ? `${option.firstname_EN} ${option.lastname_EN}`
            //     : `${option.firstname_TH} ${option.lastname_TH}`
            // }
            renderOption={(props, option) => (
              <li {...props} key={option.idEmployees}>
                {`${option.firstname_TH} ${option.lastname_TH}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                // variant="filled"
                label="พนักงาน"
                InputProps={{ ...params.InputProps, endAdornment: null }}
              />
            )}
            PopperComponent={StyledPopper}
            // noOptionsText={`${t("NoData")}`}
            isOptionEqualToValue={(option, value) =>
              option.idEmployees === value.idEmployees
            }
          />
        // </StyledBoxSearch>
      )}
    </>
  );
};

export default SelectEmployees;
