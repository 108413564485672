import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";
import {
  getCoinInReward,
  getCoinOutActivity,
  getTokenomic,
} from "../services/specialCoin.service";

export const useCoinType = ({ idCompany, select = null }) => {
  return useQuery({
    queryKey: ["coinType", idCompany],
    queryFn: async () => (await httpClient.get("company/coin-type", { params: { idCompany } })).data,
    select,
    enabled: !!idCompany,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useCoinBudget = ({ idCompany }) => {
  return useQuery({
    queryKey: ["coinBudget", idCompany],
    queryFn: async () => (await httpClient.get("company/coin-budget", { params: { idCompany } })).data,
    enabled: !!idCompany,
  });
};

export const useUpdateCoinBudget = ({ idCompany }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) => await httpClient.put(`coin-budget/${formData.idCoinType}`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coinBudget", idCompany] });
    },
  });
};

export const useCompanyCoinInTransaction = ({ idCompany, startDate, endDate }) => {
  return useQuery({
    queryKey: ["companyCoinInTransaction", idCompany, { startDate, endDate }],
    queryFn: async () => (await httpClient.get(`company/coin-transaction/in`, { params: { idCompany, startDate, endDate } })).data,
    enabled: !!idCompany,
  });
};

export const useCompanyCoinOutTransaction = ({ idCompany, startDate, endDate }) => {
  return useQuery({
    queryKey: ["companyCoinOutTransaction", idCompany, { startDate, endDate }],
    queryFn: async () => (await httpClient.get(`company/coin-transaction/out`, { params: { idCompany, startDate, endDate } })).data,
    enabled: !!idCompany,
  });
};

export const useUserCoinInTransaction = ({ idEmployees, startDate, endDate }) => {
  return useQuery({
    queryKey: ["userCoinInTransaction", idEmployees, { startDate, endDate }],
    queryFn: async () => (await httpClient.get(`coin-transaction/${idEmployees}/in`, { params: { startDate, endDate } })).data,
  });
};

export const useUserCoinOutTransaction = ({ idEmployees, startDate, endDate }) => {
  return useQuery({
    queryKey: ["userCoinOutTransaction", idEmployees, { startDate, endDate }],
    queryFn: async () => (await httpClient.get(`coin-transaction/${idEmployees}/out`, { params: { startDate, endDate } })).data,
  });
};

export const useCoinInReward = ({ idCoinType }) => {
  return useQuery({
    queryKey: ['coinInReward', idCoinType],
    queryFn: () => getCoinInReward(idCoinType),
    enabled: Boolean(idCoinType)
  });
};

export const useCoinOutActivity = ({ idCoinType }) => {
  return useQuery({
    queryKey: ['coinOutActivity', idCoinType],
    queryFn: () => getCoinOutActivity(idCoinType),
    enabled: Boolean(idCoinType)
  });
};

export const useTokenomic = ({ select }) => {
  return useQuery({
    queryKey: ["getTokenomic"],
    queryFn: getTokenomic,
    select,
  });
};
