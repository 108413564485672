import React from "react";
import { CircularProgress } from "@mui/material";
import { useCoinType } from "../../../../quries/coin";
import { useSelector } from "react-redux";

const MainCoin = ({ width }) => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { data: coinType, isPending } = useCoinType({
    idCompany: userProfile?.idCompany,
    select: (data) => data.find((item) => item.coinType === "main")
  });

  if (isPending) {
    return <CircularProgress color="secondary" />
  }

  if (coinType) {
    return (
      <img
        src={coinType?.imageURL}
        alt={coinType?.coinName}
        width={width}
        height={width}
      />
    );
  } else {
    return null;
  }
};

export default MainCoin;
