import React from "react";
import { styled } from "@mui/material/styles";
import { Container, Divider, Grid, Typography } from "@mui/material";
import { SpecialIcon } from "../../shared/typeCoin";
import ScoreBg from "./assets/score.png";
import WalletIcon from "./assets/wallet.png";
import { useSelector } from "react-redux";

const StyledRoot = styled("div")({
  backgroundColor: "#141a3c",
  minHeight: "100dvh",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapCoin = styled("div", {
  shouldForwardProp: (prop) => prop !== "showAmount",
})(({ showAmount }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 16,
  opacity: showAmount,
}));

const WrapScoreBg = styled("div")({
  backgroundImage: `url(${ScoreBg})`,
  backgroundRepeat: "no-repeat",
  width: 250,
  height: 65,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 16,
});

const LabelCoinSpecial = styled(Typography)({
  color: "#f5f5f569",
  fontSize: 24,
});

// const ScoreCoinSpecial = styled(Typography)({
//   color: "whitesmoke",
//   fontSize: 26,
//   lineHeight: 1,
//   fontWeight: "bold",
// });

export default function Wallet() {
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const coin = userProfile?.wallet.filter(
    (item) => item.coinType === "main" || item.coinType === "heart"
  );

  const holdingCoin = userProfile?.wallet.filter(
    (item) => item.isHolding && item.coinType !== "main"
  );

  return (
    <>
      {userProfile && (
        <StyledRoot className={"page"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img className="" src={WalletIcon} alt="walletIcon" width={45} />
            <Typography
              variant="h4"
              style={{ marginLeft: 10, color: "whitesmoke" }}
            >
              กระเป๋าเหรียญ
            </Typography>
          </div>
          <StyledDivider />
          <Container>
            <Grid container>
              {coin.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={6}>
                  <WrapCoin showAmount={item.amount > 0 ? 1 : 0.4}>
                    <LabelCoinSpecial>{item.coinName}</LabelCoinSpecial>
                    <SpecialIcon idCoinType={item.idCoinType} width={110} />
                    <WrapScoreBg>
                      <div style={{ padding: 4, textAlign: "center" }}>
                        <Typography
                          style={{
                            color: "whitesmoke",
                            fontSize: 34,
                            lineHeight: 1,
                            fontWeight: "bold",
                          }}
                        >
                          {item.amount}
                        </Typography>
                      </div>
                    </WrapScoreBg>
                  </WrapCoin>
                </Grid>
              ))}
            </Grid>
            <Typography
              variant="subtitle1"
              style={{ fontSize: 36, color: "whitesmoke", marginTop: 48 }}
              align="center"
            >
              {"เหรียญ Holding"}
            </Typography>
            <Grid container justifyContent="space-between">
              {holdingCoin.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                <WrapCoin showAmount={item.amount > 0 ? 1 : 0.4}>
                  <LabelCoinSpecial>{item.coinName}</LabelCoinSpecial>
                  <SpecialIcon idCoinType={item.idCoinType} width={110} />
                  <WrapScoreBg>
                    <div style={{ padding: 4, textAlign: "center" }}>
                      <Typography
                        style={{
                          color: "whitesmoke",
                          fontSize: 34,
                          lineHeight: 1,
                          fontWeight: "bold",
                        }}
                      >
                        {item.amount}
                      </Typography>
                    </div>
                  </WrapScoreBg>
                </WrapCoin>
              </Grid>
              ))}
            </Grid>
          </Container>
        </StyledRoot>
      )}
    </>
  );
}
