import { httpClient } from "./httpClient";

const getAllMoraleKPI = () => {
  return httpClient.get("morale-kpi");
};

const getMoraleKPI = ({ idMorale, ...query }) => {
  return httpClient.get(`morale-kpi/${idMorale}`, { params: query });
};

const getMoraleCompare = () => {
  return httpClient.get("morale-compare");
};

const getAllMoraleKPIYear = (idCompany) => {
  return httpClient.get("morale-kpi-year", { params: { idCompany } });
};

const getMoraleKPIYear = ({ idMorale, ...query }) => {
  return httpClient.get(`morale-kpi-year/${idMorale}`, { params: query });
};

const getDailyMoraleKPI = ({ idCompany, year, month }) => {
  return httpClient.get("morale-kpi/daily", {
    params: { idCompany, year, month },
  });
};

const getTextualSentiment = ({ idMorale, idMoraleQuestion, idEmployees }) => {
  return httpClient.get(
    `/morale/${idMorale}/question/${idMoraleQuestion}/sentiment`,
    {
      params: { idEmployees: idEmployees.join(",") },
    }
  );
};

const getMoraleKPIDaily = (query) => {
  return httpClient.get("morale-kpi/daily", { params: query });
};

export default {
  getAllMoraleKPI,
  getMoraleKPI,
  getMoraleCompare,
  getAllMoraleKPIYear,
  getMoraleKPIYear,
  getMoraleKPIDaily,
  getDailyMoraleKPI,
  getTextualSentiment,
};
