import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Chip,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { addAssessment } from "../../../../../actions/assessment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  assessmentName: yup
    .string()
    .max(200, "ห้ามเกิน 200 นะ")
    .required("กรอกมาด้วยนะ"),
  assessmentDescription: yup.string().max(500, "จำนวนตัวอักษรห้ามเกิน 500 ตัว").required("กรอกมาด้วยนะ"),
  scale: yup.number().required("กรอกมาด้วยนะ"),
  questionAssessment: yup.array().of(
    yup.object({
      question: yup
        .string()
        .max(200, "ห้ามเกิน 200 นะ")
        .required("กรอกมาด้วยนะ"),
      questionType: yup.string().required("กรอกมาด้วยนะ"),
    })
  ),
});

const CreateDuplicateAssessment = ({
  open,
  handleClose,
  selectedAssessment,
  refreshAssessments, 
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: selectedAssessment
      ? {
          assessmentName: selectedAssessment.assessmentName || "",
          assessmentDescription: selectedAssessment.assessmentDescription || "",
          scale: selectedAssessment.scale || 6,
          questionAssessment: selectedAssessment.questionList.map(
            (question) => ({
              question: question.question,
              questionType: question.questionType,
            })
          ) || [{ question: "", questionType: "" }],
        }
      : {
          assessmentName: "",
          assessmentDescription: "",
          scale: 6,
          questionAssessment: [{ question: "", questionType: "" }],
        },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questionAssessment",
  });

//   const handleAddAssessment = async (values) => {
//     console.log(values);
//     const res = await dispatch(addAssessment(values));
//     if (res && res.status === 201) {
//       toast.success("เพิ่มสำเร็จ");
//       handleClose();
//     } else {
//       toast.error("เกิดข้อมูล");
//     }
//   };

const handleAddAssessment = async (values) => {
    // Combine existing questions and new questions
    const combinedQuestions = [
      ...selectedAssessment?.questionList.map((question) => ({
        question: question.question,
        questionType: question.questionType,
      })),
      ...values.questionAssessment,
    ];
  
    const res = await dispatch(addAssessment({
      ...values,
      questionAssessment: combinedQuestions, // Use the combined question list
    }));
  
    if (res && res.status === 201) {
      toast.success("เพิ่มสำเร็จ");
      navigate("/admin/manage/assessment360");
      handleClose();
      refreshAssessments();
    } else {
      toast.error("เกิดข้อมูล");
    }
  };
  
  const handleDelete = (index) => {
    remove(index);

    selectedAssessment.questionList.splice(index, 1);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
    >
      <DialogTitle
        variant="h4"
        sx={{ marginTop: "20px", paddingLeft: "40px", fontWeight: 600 }}
      >
        สร้างชุดคำถามใหม่
      </DialogTitle>
      {/* <DialogTitle
        variant="h4"
        sx={{ marginTop: "20px", paddingLeft: "40px", fontWeight: 600 }}
      >
        {selectedAssessment?.assessmentName}
      </DialogTitle> */}

      <form onSubmit={handleSubmit(handleAddAssessment)}>
        <DialogContent sx={{ padding: "20px 40px" }}>
          <Paper sx={{ padding: "10px", marginBottom: "18px" }}>
            <Box>
              <Typography
                variant="h4"
                style={{ color: "#EC5B6A", fontSize: "12px" }}
              >
                ชื่อชุดคำถาม
              </Typography>
              <Controller
                control={control}
                name="assessmentName"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="ชื่อชุดคำถามนี้..."
                    variant="standard"
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "1px solid black",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "1px solid black",
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Paper>
          <Paper sx={{ padding: "10px", marginBottom: "18px" }}>
            <Box>
              <Typography
                variant="h6"
                style={{ color: "#EC5B6A", fontSize: "12px" }}
              >
                คำอธิบาย
              </Typography>
              <Controller
                control={control}
                name="assessmentDescription"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    maxRows={4}
                    placeholder="ชุดคำถามนี้เกี่ยวกับ..."
                    variant="standard"
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "1px solid black",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "1px solid black",
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Paper>

          <Box marginBottom={2}>
            <Controller
              control={control}
              name="scale"
              render={({ field, fieldState }) => (
                <FormControl>
                  <Typography
                    variant="h6"
                    style={{ color: "#EC5B6A", fontSize: "12px" }}
                  >
                    ระดับคำตอบ
                  </Typography>
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="5"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#EC5B6A",
                            },
                          }}
                        />
                      }
                      label="5 ระดับ"
                      sx={{ color: "#EC5B6A" }}
                    />
                    <FormControlLabel
                      value="6"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#EC5B6A",
                            },
                          }}
                        />
                      }
                      label="6 ระดับ"
                      sx={{ color: "#EC5B6A" }}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              align: "center",
            }}
          >
             <div
              disable
              sx={{
                ml: 1,
              }}
            >
              <AddCircleIcon fontSize="large" style={{ color: "#EC5B6A" }} />
            </div>
            <Typography variant="h6" sx={{ ml: 1 }}>
              คำถามของชุดคำถาม
            </Typography>
          </Box>
       

          <Box sx={{ paddingTop : "8px",  marginBottom: "16px" }}>
            {selectedAssessment?.questionList?.map((question, index) => (
              <Paper key={index} sx={{ marginBottom: "16px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Chip label={question.questionType ==="text" ? "ข้อความ" : "ตัวเลือก"} />
                  <Typography
                    variant="body1"
                    sx={{ flexGrow: 1, marginLeft: "20px" }}
                  >
                    {question.question}
                  </Typography>

                  <Grid item>
                    <IconButton
                      onClick={() => handleDelete(index)} // Use handleDelete here
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </Paper>
            ))}
          </Box>

          <Box marginTop="10px" display="flex" flexDirection="column" gap={2}>
            {fields.map((item, index) => (
              <Paper key={item.id} elevation={2} sx={{ padding: 1 }}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Controller
                    control={control}
                    name={`questionAssessment.${index}.questionType`}
                    render={({ field, fieldState }) => (
                      <TextField
                      {...field}
                      select
                      fullWidth
                      sx={{
                        maxWidth: "160px",
                        "& .MuiInputLabel-root": {
                          color: "#EC5B6A",
                        },
                        "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled": {
                          color: "#EC5B6A",
                        },
                        "& .MuiSelect-icon": {
                          color: "#EC5B6A",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#EC5B6A",
                          },
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "1px solid #EC5B6A",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "1px solid #EC5B6A",
                        },
                      }}
                        label="เลือกประเภทคำถาม"
                        variant="standard"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      >
                        <MenuItem value={"choice"}>{"ตัวเลือก"}</MenuItem>
                        <MenuItem value={"text"}>{"ข้อความ"}</MenuItem>
                      </TextField>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`questionAssessment.${index}.question`}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="กรอกคำถาม"
                        label="คำถาม"
                        variant="standard"
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                        sx={{
                          "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled": {
                            color: "#EC5B6A",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#EC5B6A", 
                          },
                          "& .MuiSelect-icon": {
                            color: "#EC5B6A", 
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EC5B6A", 
                            },
                          },
                          "& .MuiInput-underline:before": {
                            borderBottom: "1px solid #EC5B6A",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: "1px solid #EC5B6A",
                          },
                        }}
                      />
                    )}
                  />
                  <IconButton
                    onClick={() => remove(index)}
                    disabled={fields.length === 1}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Paper>
            ))}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => append({ question: "", questionType: "" })}
                disabled={fields.length >= 5}
                sx={{
                  color: "#EC5B6A",         
                  borderColor: "#EC5B6A",           
                }}
                startIcon={<AddCircleOutlineIcon />}
              >
                {"เพิ่มคำถาม"}
              </Button>
            </div>
          </Box>


          <DialogActions>
            <Button
              variant="outlined"
              color="error"
              onClick={handleClose}
              sx={{
                color: "gray",
                borderColor: "gray",
                "&:hover": {
                  borderColor: "darkgrey",
                  backgroundColor: "rgba(128, 128, 128, 0.1)",
                },
                borderRadius: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              type="submit"
              sx={{
                backgroundColor: "#EC5B6A",
                "&:hover": { backgroundColor: "#EC5B6A" },
                borderRadius: 1,
              }}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateDuplicateAssessment;
