import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import dayjs from "dayjs";
import { Bar, Doughnut } from "react-chartjs-2";
import utils from "../../../../utils";
import LoadingIcon from "../../shared/general/loadingIcon";
import { useSelector } from "react-redux";
import { useCoinBudget } from "../../../../quries/coin";
import { useCompanyBudget, useCostReward } from "../../../../quries/company";
import { DatePicker } from "@mui/x-date-pickers";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const StyledFormControlLabelRadio = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: "white",
  },
});

const StyledTablePagination = styled(TablePagination)({
  color: "white",
  "& .MuiTablePagination-selectLabel": {
    color: "white",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "white",
  },
  "& .MuiIconButton-root.Mui-disabled": {
    color: "white",
  },
});

const StyledTableContainer = styled(TableContainer)({
  height: 408,
  backgroundColor: "transparent",
});

const StyledDatePicker = styled(DatePicker)({
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffffff",
    },
  },
  "& .MuiIconButton-root": {
    color: "#ffffff",
  },
});

const StyledIconButton = styled(IconButton)({
  color: "white",
  "&:hover": {
    backgroundColor: "#ffffff42",
  },
});

const WrapHeaderSection = styled("div")({
  display: "flex",
  marginTop: 24,
});

const WrapHeaderSectionFilterDate = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
});

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: "white",
    borderBottom: "1px solid #404040",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "white",
    borderBottom: "1px solid #404040",
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}));

const CostRewardTable = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const companyBudget = useCompanyBudget({ idCompany: userProfile?.idCompany });

  const coinBudget = useCoinBudget({ idCompany: userProfile?.idCompany });
  const costReward = useCostReward({ idCompany: userProfile?.idCompany });

  const [filterDate, setFilterDate] = useState(dayjs());
  const [selectedView, setSelectedView] = React.useState("month");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeView = (event) => {
    setSelectedView(event.target.value);
  };

  const handleChangeMonth = (action) => {
    if (action === 1) {
      if (dayjs().isSame(filterDate.add(1, "month"), "month")) {
        setFilterDate(dayjs());
      } else {
        setFilterDate((prev) => prev.add(1, "month"));
      }
    } else if (action === -1) {
      setFilterDate((prev) => prev.subtract(1, "month"));
    }
  };

  const rowsFilter = () => {
    return costReward.data?.filter((value) =>
      filterDate.isSame(dayjs(value.redeemDate), selectedView)
    );
  };

  if (coinBudget.isPending || companyBudget.isPending || costReward.isPending) {
    return <LoadingIcon />;
  }

  const costRewardMonth = costReward.data?.reduce((acc, curr) => {
    acc[dayjs(curr.redeemDate).get("month")] += curr.price * curr.quantity;
    return acc;
  }, new Array(12).fill(0));

  const totalCostRedeem = costRewardMonth.reduce((acc, curr) => acc + curr, 0);

  const totalCoinBudget = coinBudget.data.reduce(
    (acc, cur) => acc + cur.budget,
    0
  );

  const totalCost =
    companyBudget.data?.companyRewardCost + companyBudget.data?.uniRewardCost;
  const costPercentage =
    (totalCost * 100) / companyBudget.data?.uniCultureCredit;
  const redeemPercentage = totalCost ? (totalCostRedeem / totalCost) * 100 : 0;

  return (
    <div style={{ marginBottom: 16, marginTop: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div style={{ height: 250 }}>
            <Doughnut
              data={{
                labels: ["Cost", "Budget"],
                datasets: [
                  {
                    data: [costPercentage, 100 - costPercentage],
                    backgroundColor: ["rgb(53,185,233)", "#ccc"],
                    borderColor: ["rgb(53,185,233)", "#ccc"],
                    hoverBackgroundColor: ["rgb(53,185,233)", "#ccc"],
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 75,
                elements: {},
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: false,
                  // mode: "dataset"
                },
                plugins: {
                  doughnutlabel: {
                    labels: [
                      {
                        text: "ใช้ไปแล้ว",
                        color: "#737373",
                        font: {
                          size: 18,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                      {
                        text: utils.numberWithCommas(totalCost),
                        color: "whitesmoke",
                        font: {
                          size: 38,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                      {
                        text: "----------------",
                        color: "#737373",
                        font: {
                          size: 20,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                      {
                        text: "BUDGET",
                        color: "#737373",
                        font: {
                          size: 14,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                      {
                        text: utils.numberWithCommas(totalCoinBudget),
                        color: "rgba(245,245,245,.8)",
                        font: {
                          size: 18,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                    ],
                  },
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ height: 250 }}>
            <Doughnut
              data={{
                labels: ["Cost", "Budget"],
                datasets: [
                  {
                    data: [redeemPercentage, 100 - redeemPercentage],
                    backgroundColor: ["rgb(53,185,233)", "#ccc"],
                    borderColor: ["rgb(53,185,233)", "#ccc"],
                    hoverBackgroundColor: ["rgb(53,185,233)", "#ccc"],
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 75,
                elements: {},
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: false,
                  // mode: "dataset"
                },
                plugins: {
                  doughnutlabel: {
                    labels: [
                      {
                        text: "แลกไปแล้ว",
                        color: "#737373",
                        font: {
                          size: 18,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                      {
                        text: utils.numberWithCommas(totalCostRedeem),
                        color: "whitesmoke",
                        font: {
                          size: 38,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                      {
                        text: "----------------",
                        color: "#737373",
                        font: {
                          size: 20,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                      {
                        text: "รวมในระบบ",
                        color: "#737373",
                        font: {
                          size: 14,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                      {
                        text: utils.numberWithCommas(totalCost),
                        color: "rgba(245,245,245,.8)",
                        font: {
                          size: 18,
                          family:
                            'Roboto,Sarabun,"Helvetica Neue",Arial,sans-serif',
                          weight: "bold",
                        },
                      },
                    ],
                  },
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
      <WrapHeaderSection>
        <Tooltip title="เดือนก่อน" aria-label="labelBeforeMonth">
          <StyledIconButton
            onClick={() => handleChangeMonth(-1)}
            aria-label="beforeMonth"
            size="small"
          >
            <KeyboardArrowLeftIcon fontSize="inherit" />
          </StyledIconButton>
        </Tooltip>
        <Typography
          variant="h6"
          style={{
            textTransform: "uppercase",
            color: "white",
            margin: "0 10px",
          }}
        >
          {dayjs(filterDate).format("MMMM YYYY")}
        </Typography>
        <Tooltip title="เดือนหน้า" aria-label="labelNextMonth">
          <span>
            <StyledIconButton
              onClick={() => handleChangeMonth(1)}
              aria-label="nextMonth"
              size="small"
              disabled={dayjs().isSame(filterDate, "month")}
            >
              <KeyboardArrowRightIcon fontSize="inherit" />
            </StyledIconButton>
          </span>
        </Tooltip>
      </WrapHeaderSection>
      <WrapHeaderSectionFilterDate>
        <FormControl>
          <RadioGroup
            style={{ color: "white" }}
            row
            aria-label="view"
            name="view"
            value={selectedView}
            onChange={handleChangeView}
          >
            <StyledFormControlLabelRadio
              style={{ marginBottom: 0, color: "white" }}
              value="month"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": { color: "#f50057" },
                  }}
                />
              }
              label="ทั้งเดือน"
            />
            <StyledFormControlLabelRadio
              style={{ marginBottom: 0, color: "white" }}
              value="day"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": { color: "#f50057" },
                  }}
                />
              }
              label="วันที่"
            />
          </RadioGroup>
        </FormControl>
        {selectedView === "day" && (
          <StyledDatePicker
            value={filterDate}
            minDate={filterDate.startOf("month")}
            maxDate={dayjs()}
            onChange={(newValue) => setFilterDate(newValue)}
          />
        )}
      </WrapHeaderSectionFilterDate>
      <StyledTableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="a dense table">
          <TableHead style={{ backgroundColor: "#282933" }}>
            <TableRow>
              <StyledTableCell align="center">วันที่</StyledTableCell>
              <StyledTableCell align="center">ชื่อ-หน่วยงาน</StyledTableCell>
              <StyledTableCell align="center">รายการ</StyledTableCell>
              <StyledTableCell align="center">sku</StyledTableCell>
              <StyledTableCell align="right">ราคา</StyledTableCell>
              <StyledTableCell align="right">จำนวน</StyledTableCell>
              <StyledTableCell align="right">มูลค่ารวม</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsFilter()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {dayjs(row.redeemDate).format("D MMM YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {`${row.firstname_TH} ${row.lastname_TH}`}
                      {row.departmentName && ` - ${row.departmentName}`}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">{row.sku}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.price > 0 && utils.numberWithCommas(row.price)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.quantity}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {utils.numberWithCommas(row.price * row.quantity)}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <StyledTablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsFilter().length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Typography
        variant="subtitle1"
        style={{
          marginTop: 16,
          color: "white",
          marginBottom: 16,
          fontSize: 18,
        }}
      >
        สรุปข้อมูลรายปี {filterDate.year()}
      </Typography>
      <div style={{ height: 250, marginBottom: 16 }}>
        <Bar
          data={{
            labels: monthNames,
            datasets: [
              {
                label: "Cost",
                data: costRewardMonth,
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)",
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "rgba(80,80,80,.2)",
                    lineWidth: 1,
                    display: true,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "rgba(80,80,80,.2)",
                    lineWidth: 1,
                    display: true,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
          }}
        />
      </div>
    </div>
  );
};

export default CostRewardTable;
