import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import utils from "../../../../utils";
import LoadingIcon from "../../shared/general/loadingIcon";
import { useCoinBudget } from "../../../../quries/coin";
import { SpecialIcon } from "../../shared/typeCoin";

const BudgetCoinTable = ({ companySelected }) => {
  const { data, isPending } = useCoinBudget({
    idCompany: companySelected?.idCompany,
  });

  const totalBudget = data?.reduce((acc, cur) => {
    return acc + cur.budget;
  }, 0);

  if (isPending) {
    return <LoadingIcon />;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 600,
        backgroundColor: "#000000",
        border: "1px solid #2e2e2e",
      }}
    >
      <Table stickyHeader sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: "#000000",
                color: "#ffffff",
                fontSize: "1.25rem",
                borderColor: "#2e2e2e",
              }}
            >
              {"เหรียญ"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: "#000000",
                color: "#ffffff",
                fontSize: "1.25rem",
                borderColor: "#2e2e2e",
              }}
            >
              {"งบประมาณ"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: "#000000",
                color: "#ffffff",
                fontSize: "1.25rem",
                borderColor: "#2e2e2e",
              }}
            >
              {"มูลค่าต่อเหรียญ"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: "#000000",
                color: "#ffffff",
                fontSize: "1.25rem",
                borderColor: "#2e2e2e",
              }}
            >
              {"จำนวนทั้งหมด"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: "#000000",
                color: "#ffffff",
                fontSize: "1.25rem",
                borderColor: "#2e2e2e",
              }}
            >
              {"จำนวนในระบบ"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((coin) => (
            <TableRow
              key={coin.idCoinType}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ borderColor: "#2e2e2e" }}
              >
                <div style={{ display: "flex", gap: "1rem" }}>
                  <img
                    src={coin?.imageURL}
                    alt={coin?.coinName}
                    width={40}
                    height={40}
                  />
                  <Typography color={"#ffffff"} fontSize={18}>
                    {coin.coinName}
                  </Typography>
                </div>
              </TableCell>
              <TableCell align="center" sx={{ borderColor: "#2e2e2e" }}>
                <Typography color={"#ffffff"} fontSize={18}>
                  {utils.numberWithCommas(coin.budget || 0)}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ borderColor: "#2e2e2e" }}>
                <Typography color={"#ffffff"} fontSize={18}>
                  {coin.totalCoin > 0
                    ? utils.numberWithCommas(coin.budget / coin.totalCoin)
                    : 0}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}
              >
                <Typography color={"#ffffff"} fontSize={18}>
                  {utils.numberWithCommas(coin.totalCoin || 0)}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}
              >
                <Typography color={"#ffffff"} fontSize={18}>
                  {utils.numberWithCommas(coin.coinInSystem || 0)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="right" colSpan={5}>
              <Typography variant="h5" color={"#ffffff"}>
                {`${"งบประมาณรวม"}: ${utils.numberWithCommas(totalBudget)}`}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BudgetCoinTable;
