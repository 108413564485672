import React, { useState } from "react";
import {
  Button,
  Container,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import SelectAffiliateCompanyDark from "../../shared/general/selectAffiliateCompanyDark";
import BugetCoinEdit from "./budgetCoinEdit";
import BudgetCoinTable from "./budgetCoinTable";

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  backgroundColor: "#ffffff",
});

const BudgetCoin = () => {
  const [companySelected, setCompanySelected] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={"page dashboard-page"}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" color={"#ffffff"}>
          งบประมาณเหรียญรางวัล
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<SettingsIcon />} 
          onClick={handleClickOpen}
        >
          ตั้งค่า
        </Button>
      </div>
      <StyledDivider />
      <Container>
        <div style={{ marginBottom: 16 }}>
          <SelectAffiliateCompanyDark
            value={companySelected}
            onChange={setCompanySelected}
            defaultSelect={true}
          />
        </div>
        <BudgetCoinTable companySelected={companySelected} />
      </Container>
      {open && (
        <BugetCoinEdit
          open={open}
          handleClose={handleClose}
          companySelected={companySelected}
        />
      )}
    </div>
  );
};

export default BudgetCoin;
