import {
  ASSESSMENT_FETCHING,
  ASSESSMENT_FAILED,
  ASSESSMENT_SUCCESS,
  ASSESSMENT_ANSWER_FETCHING,
  ASSESSMENT_ANSWER_FAILED,
  ASSESSMENT_ANSWER_SUCCESS,
  ASSESSMENT_ITEM_FETCHING,
  ASSESSMENT_ITEM_SUCCESS,
  ASSESSMENT_ITEM_FAILED,
} from "./types";

import AssessmentService from "../services/assessment.service";

export const getAllAssessment = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_FETCHING,
    });
    const res = await AssessmentService.getAllAssessment(query);
    if (res) {
      dispatch({
        type: ASSESSMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const getAssessment = (idAssessment) => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_ITEM_FETCHING,
    });
    const res = await AssessmentService.getAssessment(idAssessment);
    if (res) {
      dispatch({
        type: ASSESSMENT_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const getAnswerByUserId = (idAssessment, assignTo) => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_ANSWER_FETCHING,
    });
    const res = await AssessmentService.getAnswerByUserId(idAssessment, assignTo);
    if (res) {
      dispatch({
        type: ASSESSMENT_ANSWER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_ANSWER_FAILED,
    });
    console.log(err);
  }
};

export const addAssessment = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.addAssessment(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const updateAssessment = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.updateAssessment(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const deleteAssessment = (idAssessment) => async (dispatch) => {
  try {
    const res = await AssessmentService.deleteAssessment(idAssessment);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const updateAssessmentQuestion = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.updateAssessmentQuestion(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};


export const getAssessmentAnswer = () => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_ANSWER_FETCHING,
    });
    const res = await AssessmentService.getAssessmentAnswer();
    if (res) {
      dispatch({
        type: ASSESSMENT_ANSWER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_ANSWER_FAILED,
    });
    console.log(err);
  }
};

export const updateAssessmentFavorite = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.updateAssessmentFavorite(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
  }
};