import React from "react";
import { useCoinType } from "../../../../quries/coin";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const SpecialIcon = ({ idCoinType, idCompany, width }) => {
  const { result: userProfile } = useSelector((state) => state.userProfile);

  let selectIdCompany = null
  if (idCompany) {
    selectIdCompany = idCompany
  } else if (userProfile) {
    selectIdCompany = userProfile.idCompany
  }
  
  const { data: coinType, isPending } = useCoinType({
    idCompany: selectIdCompany,
    select: (data) => data.find((item) => item.idCoinType === idCoinType)
  });
  
  if (isPending) {
    return <CircularProgress color="secondary" />;
  }

  if (coinType) {
    return (
      <img
        src={coinType?.imageURL}
        alt={coinType?.coinName}
        width={width}
        height={width}
      />
    );
  } else {
    return null;
  }
};

export default SpecialIcon;
