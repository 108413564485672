import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TablePagination,
  Chip,
} from "@mui/material";

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

const Assign = ({ assign }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log(assign)

  return (
    <div>
      {assign
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item) => (
          <Fragment key={item.idAppraisee_SampleSize}>
            <ListItem
              secondaryAction={
                <Chip
                  label={item.isComplete ? "ประเมินแล้ว" : "ยังไม่ประเมิน"}
                  color={item.isComplete ? "success" : "error"}
                />
              }
            >
              <ListItemAvatar>
                <Avatar src={item.imageURL} />
              </ListItemAvatar>

              <ListItemText
                primary={item.idAppraiser ? `${item.firstname_TH} ${item.lastname_TH}` : item.email}
                secondary={item.idAppraiser ? item.positionName : "outsider"} 
           
              />

     
            </ListItem>
            <StyledDivider variant="inset" />
          </Fragment>
        ))}

      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={assign.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Assign;
