import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const strengthWeaknessTable = ({ topTopics, type }) => {
  const cellBg =
    type === "Strength"
      ? "linear-gradient(90deg, #A0E3E2, #C4A1D9)"
      : "linear-gradient(90deg, #F6B8B8, #F9E1C5)";

  return (
    <>
      <Typography
        variant="h5"
        textAlign="left"
        color="#ffffff"
        style={{ marginTop: "16px" }}
      >
        {type === "Strength" ? "จุดแข็ง 3 อันดับแรก" : "จุดอ่อน 3 อันดับแรก"}
      </Typography>
      <TableContainer
        component={Paper}
        style={{ marginTop: "16px", backgroundColor: "#424242" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#ffffff",
                  fontSize: "1rem",
                  border: "none",
                  width: "50%",
                }}
              >
                <div
                  style={{
                    background: cellBg,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <strong>Topic</strong>
                </div>
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color: "#ffffff",
                  fontSize: "1rem",
                  border: "none",
                  width: "50%",
                }}
              >
                <div
                  style={{
                    background: cellBg,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  คะแนนเฉลี่ย (%)
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topTopics
              .filter((row) => row.type === type)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      color: "white",
                      border: "none",
                      backgroundColor: cellBg,
                    }}
                  >
                    {row.questionTopic}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      border: "none",
                      backgroundColor: cellBg,
                    }}
                  >
                    {Math.ceil(row.average)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default strengthWeaknessTable;
