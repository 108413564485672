import { httpClient } from "./httpClient";

const getAllMorale = (query) => {
  return httpClient.get("morale", { params: query });
};

//Morale
const getMorale = (idMorale) => {
  return httpClient.get("morale/" + idMorale);
};

const addMorale = (formData) => {
  return httpClient.post("morale", formData);
};

const updateMorale = (formData) => {
  return httpClient.patch("morale", formData);
};

const deleteMorale = (idMorale) => {
  return httpClient.delete("morale/" + idMorale);
};

//Question
const addMoraleQuestion = (formData) => {
  return httpClient.post("morale-question", formData);
};

const updateMoraleQuestion = (formData) => {
  return httpClient.patch("morale-question", formData);
};

const deleteMoraleQuestion = (idQuestion) => {
  return httpClient.delete("morale-question/" + idQuestion);
};

//AddParticipant
const addMoraleParticipant = (formData) => {
  return httpClient.post("morale-participant", formData);
};

//Answer
const addMoraleAnswer = (formData) => {
  return httpClient.post("morale-answer", formData);
};

//Feedback
const addMoraleQuestionFeedback = (formData) => {
  return httpClient.post("morale-question-feedback", formData);
};

//MoraleUser
const getMoraleUser = (idEmployees) => {
  return httpClient.get("morale-user/" + idEmployees);
};

export default {
  getAllMorale,
  getMorale,
  addMorale,
  updateMorale,
  deleteMorale,
  addMoraleQuestion,
  updateMoraleQuestion,
  deleteMoraleQuestion,
  addMoraleParticipant,
  addMoraleAnswer,
  addMoraleQuestionFeedback,
  getMoraleUser,
};
