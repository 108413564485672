import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { 
  Typography, 
  Box, 
  Divider, 
  Chip, 
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";
import {
  useEmployeeLevel,
  useEmploymentType,
  useOrganization,
} from "../../../../quries/company";
import { useMoraleCompany, useMoraleKPI } from "../../../../quries/morale";
import MoraleYearlyFilter from "./filter/filterMoraleDashboard";
import MoraleDetail from "./detail";
import Monthly from "./monthly";

const MoraleKpi = () => {
  return (
    <div style={{width:'100%'}}>
     <Monthly/>
    </div>
  );
};

export default MoraleKpi;
