import { httpClient } from "./httpClient";

//MoraleDailyQuestion
const getAllMoraleDailyQuestion = () => {
  return httpClient.get("morale-dailyquestion");
};

const addMoraleDailyQuestion = (formData) => {
  return httpClient.post("morale-dailyquestion", formData);
};

const updateMoraleDailyQuestion = (formData) => {
  return httpClient.patch("morale-dailyquestion", formData);
};

//MoraleDaily
const getAllMoraleDaily = () => {
  return httpClient.get("morale-daily");
};

const getMoraleDaily = (id) => {
  return httpClient.get("morale-daily/" + id);
};

const addMoraleDaily = (formData) => {
  return httpClient.post("morale-daily", formData);
};

//MoraleDailyAnswer
const addMoraleDailyAnswer = (formData) => {
  return httpClient.post(`morale-daily/${formData.idMoraleDaily}/answer`, formData);
};

const getMoraleDailyByUserId = () => {
  return httpClient.get("morale-daily-user");
};

const getMoraleDailyCurrent = () => {
  return httpClient.get("morale-daily-current");
};

const getQuestionDailyAnswerText = (query) => {
  return httpClient.get("get-daily-answers", { params: query });
};

export default {
  getAllMoraleDailyQuestion,
  addMoraleDailyQuestion,
  updateMoraleDailyQuestion,
  getAllMoraleDaily,
  getMoraleDaily,
  addMoraleDaily,
  addMoraleDailyAnswer,
  getMoraleDailyByUserId,
  getMoraleDailyCurrent,
  getQuestionDailyAnswerText,
};
