import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell,
} from "@mui/material";

import { styled } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";

const StyledTableCell = styled(TableCell)({
  color: "#ffffff",
  fontWeight: "bold",
  padding: "16px",
  borderColor: "#2e2e2e",
  width: "50%",
});

const getTop3Weaknesses = (filterData) => {
  const topicWeaknesses = [];
  filterData.forEach((row) => {
    row.questionTopics.forEach((item) => {
      topicWeaknesses.push({
        topic: item.questionTopic,
        averageScore: item.averageScore,
      });
    });
  });

  return topicWeaknesses
    .sort((a, b) => a.averageScore - b.averageScore)
    .slice(0, 3);
};

const getScoreColor = (score) => {
  if (score >= 80) return { color: "green" };
  if (score >= 60) return { color: "yellowgreen" };
  if (score >= 40) return { color: "yellow" };
  if (score >= 20) return { color: "orange" };
  return { color: "red" };
};

const Top3WeaknessDailyTable = ({ filterData }) => {
  const top3Weaknesses = getTop3Weaknesses(filterData);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <PersonIcon sx={{ color: "#ffffff" }} />
          <Typography variant="h5" color={"#ffffff"}>
            จุดอ่อน 3 อันดับแรก
          </Typography>
        </div>
      </div>

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 600,
          backgroundColor: "#000000",
          border: "1px solid #2e2e2e",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  background: "linear-gradient(90deg, #F6B8B8, #F9E1C5)",
                }}
              >
                หัวข้อ
              </StyledTableCell>
              <StyledTableCell
                style={{
                  background: "linear-gradient(90deg, #F6B8B8, #F9E1C5)",
                }}
                align="center"
              >
                คะแนนเฉลี่ย (%)
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {top3Weaknesses.map((row) => (
              <TableRow key={row.topic}>
                <StyledTableCell>{row.topic}</StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={getScoreColor(row.averageScore)}
                >
                  {Math.ceil(row.averageScore)}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Top3WeaknessDailyTable;
