import React, { useEffect, useState } from "react";
import HeaderPage from "../../shared/header/headerPage";
import CompanySendCoinGrid from "./CompanySendCoinGrid";
import { Autocomplete, Button, Container, Divider, Grid, Stack, styled, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GridOnIcon from "@mui/icons-material/GridOn";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/user";
import {
  addCompanySendCoin,
  deleteCompanySendCoin,
  getCompanySendCoin,
} from "../../../../actions/companySendCoin";
import DialogSendCoin from "./DialogSendCoin";
import DialogExcelSendCoin from "./DialogExcelSendCoin";
import { toast } from "sonner";
import { useOrganization } from "../../../../quries/company";
import LoadingIcon from "../../shared/general/loadingIcon";
import SelectEmployees from "../../shared/general/selectEmployees";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const CompanySendCoin = () => {
  const organization = useOrganization();
  const dispatch = useDispatch();
  const { result: users } = useSelector((state) => state.users);
  const { result: companySendCoin, isFetching } = useSelector(
    (state) => state.companySendCoin
  );

  const [open, setOpen] = React.useState(false);
  const [openExcel, setOpenExcel] = React.useState(false);
  const [companySelected, setCompanySelected] = useState(null);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentName: "ทั้งหมด",
    idDepartment: 0,
  });
  const [sectionSelected, setSectionSelected] = useState({
    sectionName: "ทั้งหมด",
    idSection: 0,
  });

  const handleChangeEmployee = (value) => {
    setEmployeeSelected(value);
    setCompanySelected(null);
    setDepartmentSelected({
      departmentName: "ทั้งหมด",
      idDepartment: 0,
    });
    setSectionSelected({
      sectionName: "ทั้งหมด",
      idSection: 0,
    });
  };
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseExcel = () => {
    setOpenExcel(false);
  };

  const handleClickOpenExcel = () => {
    setOpenExcel(true);
  };

  const deleteSendCoin = async (id) => {
    const res = await dispatch(deleteCompanySendCoin(id));
    if (res.status === 200) {
      dispatch(getCompanySendCoin());
      toast.success("สำเร็จ");
    } else {
      toast.error("เกิดข้อผิดพลาด");
    }
  };

  const handleAddSubmit = async (formData) => {
    const res = await dispatch(addCompanySendCoin(formData));
    if (res.status === 201) {
      dispatch(getCompanySendCoin());
      toast.success("สำเร็จ");
    } else {
      toast.error("เกิดข้อผิดพลาด");
    }
    handleClose();
  };

  const rows = companySendCoin
    ? companySendCoin.filter((item) => {
        if (employeeSelected) {
          return item.idEmployees === employeeSelected.idEmployees;
        }

        const filterDepartment = departmentSelected.idDepartment
          ? departmentSelected.idDepartment === item.idDepartment
          : true;

        const filterSection = sectionSelected.idSection
          ? sectionSelected.idSection === item.idSection
          : true;

        return filterDepartment && filterSection;
      })
    : [];

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  useEffect(() => {
    if (organization.data) {
      setCompanySelected(organization.data[0])
    }
  }, [organization.data]);

  useEffect(() => {
    if (companySelected) {
      dispatch(getCompanySendCoin({ idCompany: companySelected.idCompany }));
    }
  }, [companySelected]);


  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"ส่งเหรียญ"} />
      <StyledDivider />
      <Container>
        <Grid container alignItems={"center"} spacing={1} marginBottom={2}>
          <Grid item xs={12} sm={12} md={3}>
            <Autocomplete
              disableClearable
              value={companySelected}
              options={organization.data}
              onChange={(event, newValue) => {
                setCompanySelected(newValue);
                setEmployeeSelected(null);
                setDepartmentSelected({
                  departmentName: "ทั้งหมด",
                  idDepartment: 0,
                });
                setSectionSelected({
                  sectionName: "ทั้งหมด",
                  idSection: 0,
                });
              }}
              getOptionLabel={(option) => option.companyName}
              isOptionEqualToValue={(option, value) =>
                option.idCompany === value.idCompany
              }
              renderInput={(params) => <TextField {...params} label="บริษัท" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disableClearable
              value={departmentSelected}
              options={
                companySelected
                  ? [
                      { departmentName: "ทั้งหมด", idDepartment: 0 },
                      ...companySelected.department,
                    ]
                  : []
              }
              disabled={!companySelected || employeeSelected}
              onChange={(event, newValue) => setDepartmentSelected(newValue)}
              getOptionLabel={(option) => option.departmentName}
              isOptionEqualToValue={(option, value) =>
                option.idDepartment === value.idDepartment
              }
              renderInput={(params) => <TextField {...params} label="ส่วน" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disableClearable
              value={sectionSelected}
              options={
                companySelected
                  ? [
                      { sectionName: "ทั้งหมด", idSection: 0 },
                      ...companySelected.section,
                    ]
                  : []
              }
              disabled={!companySelected || employeeSelected}
              onChange={(event, newValue) => setSectionSelected(newValue)}
              getOptionLabel={(option) => option.sectionName}
              isOptionEqualToValue={(option, value) =>
                option.idSection === value.idSection
              }
              renderInput={(params) => <TextField {...params} label="แผนก" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SelectEmployees
              value={employeeSelected}
              handleChange={handleChangeEmployee}
            />
          </Grid>
        </Grid>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={1}
          marginBottom={4}
          flexWrap={"wrap"}
        >
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            onClick={handleClickOpen}
          >
            ส่งเหรียญ
          </Button>
          {/* <Button
            variant="contained"
            color="success"
            startIcon={<UploadFileIcon />}
            onClick={handleClickOpenExcel}
          >
            อัพโหลดไฟล์
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<GridOnIcon />}
            href={`${process.env.REACT_APP_API_URL}files/send-coin.csv`}
            download
          >
            ตัวอย่างไฟล์
          </Button> */}
        </Stack>
        {isFetching ? (
          <LoadingIcon />
        ) : (
          <CompanySendCoinGrid
            data={rows}
            deleteSendCoin={deleteSendCoin}
          />
        )}
      </Container>
      {users && (
        <>
          <DialogExcelSendCoin
            users={users}
            handleAddSubmit={handleAddSubmit}
            openExcel={openExcel}
            handleCloseExcel={handleCloseExcel}
          />
          <DialogSendCoin
            users={users}
            handleAddSubmit={handleAddSubmit}
            open={open}
            handleClose={handleClose}
            idCompany={companySelected?.idCompany}
          />
        </>
      )}
    </StyledRoot>
  );
};

export default CompanySendCoin;
