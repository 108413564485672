import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Stack,
  Box,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarsIcon from "@mui/icons-material/Stars";
import MainTransfer from "./mainTransfer";
import HeartTransfer from "./heartTransfer";
import SelectAffiliateCompanyDark from "../../shared/general/selectAffiliateCompanyDark";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "0px auto",
})

export default function Transfer() {
  const [companySelected, setCompanySelected] = useState(null);
  const [view, setView] = useState("heart");
  // const { result: tokenomic } = useSelector((state) => state.tokenomic);

  return (
    <StyledRoot className={"page dashboard-page"}>
      <Typography variant="h4" color={"#ffffff"}>
        สรุปเหรียญ
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        marginTop={2}
      >
        <div style={{ maxWidth: 320, width: "100%" }}>
          <SelectAffiliateCompanyDark
            value={companySelected}
            onChange={setCompanySelected}
            defaultSelect={true}
          />
        </div>
        <BottomNavigation
          showLabels
          style={{ maxWidth: 160 }}
          value={view}
          onChange={(event, newValue) => {
            setView(newValue);
          }}
        >
          <BottomNavigationAction
            value={"main"}
            label="Main"
            icon={<StarsIcon />}
          />
          <BottomNavigationAction
            value={"heart"}
            label="Heart"
            icon={<FavoriteIcon />}
          />
        </BottomNavigation>
      </Stack>
      <Box paddingTop={6}>
        {view === "main" && <MainTransfer idCompany={companySelected?.idCompany} />}
        {view === "heart" && <HeartTransfer idCompany={companySelected?.idCompany} />}
      </Box>
    </StyledRoot>
  );
}
