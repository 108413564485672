import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import {
  Container,
  Tooltip,
  Button,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import HeaderPage from "../../shared/header/headerPage";
import iconHeader from "./assets/360-degrees-color.svg";
import AssessmentList from "./AssessmentList";
import { addAssessment } from "../../../../actions/assessment";
import { getAllEmployees } from "../../../../actions/user";
import CreateAssessment from "./createAssessment/CreateAssessment";
import {
  getAllAssessment,
} from "../../../../actions/assessment";
import {
  getAllAppraisee,
} from "../../../../actions/appraisee";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF",
  "& .wrapHeader": {
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media only screen and (max-width:600px)"]: {
      flexDirection: "column",
    },
  },
});

export default function Assessment360() {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [excelData, setExcelData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [questionType, setquestionType] = useState("");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [showInput, setShowInput] = useState(false);
  const [question, setQuestion] = useState("");
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [alignment, setAlignment] = React.useState("left");

  const handleClickDeleteQuestion = (index) => {
    let newValue = { ...assessment };
    newValue.questionAssessment.splice(index, 1);
    setAssessment(newValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSaveAssessment = async () => {
    const assessmentData = {
      ...assessment,
      questionAssessment: questionsList, 
    };

    const res = await dispatch(addAssessment(assessmentData));
    if (res) {
      navigate("/admin/manage/assessment360");
    }

    setOpen(false);
  };

  const handleChangeQuestion = (index) => (event) => {
    let newValue = { ...assessment };
    newValue.questionAssessment[index] = event.target.value;

    setAssessment(newValue);
  };

  const handleAddQuestionClick = () => {
    setShowInput(true);
    setQuestionsList((prevList) => [...prevList, question]);
  };

  const handleSaveQuestion = () => {
    if (question && questionType) {
      console.log("New question saved:", question);

      // Add the new question to the list and store it in state
      setQuestionsList((prevList) => {
        const updatedList = [...prevList, { question, questionType }];
        console.log("All entered questions:", updatedList); // Log the updated list
        return updatedList;
      });

      // Clear input fields
      setQuestion("");
      setquestionType("");
    } else {
      console.warn("Question and type cannot be empty");
    }
  };

  const handleOpenConfirm = () => {
    const passAssessmentName = assessment.assessmentName.trim().length !== 0;
    const passAssessmentDesc =
      assessment.assessmentDescription.trim().length !== 0;
    let passQuestion = true;
    for (let i = 0; i < assessment.questionAssessment.length; i++) {
      if (assessment.questionAssessment[i].trim().length === 0) {
        passQuestion = false;
        break;
      }
    }

    if (passAssessmentName && passAssessmentDesc && passQuestion) {
      setOpenConfirm(true);
    } else {
      setOpenSnackbar(true);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  const [assessment, setAssessment] = React.useState({
    assessmentType: null,
    // appraisee: [""],
    assessmentName: "",
    assessmentDescription: "",
    questionAssessment: null,
    scale: 6,
    // createBy: userProfile.idEmployees,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleClickCreateAssessment = () => {
  //   navigate("/admin/manage/createassessment360");
  // };

  const handleClickCreateAssessment = () => {
    setOpen(true);
  };

  const handleChangeAssessmentName = (event) => {
    let newValue = { ...assessment };
    newValue.assessmentName = event.target.value;

    setAssessment(newValue);
  };

  const handleChangeAssessmentDesc = (event) => {
    let newValue = { ...assessment };
    newValue.assessmentDescription = event.target.value;

    setAssessment(newValue);
  };

  const [isAddQuestionEnabled, setIsAddQuestionEnabled] = useState(false);

  const checkFormCompletion = () => {
    const isComplete =
      assessment.assessmentName.trim() !== "" &&
      assessment.assessmentDescription.trim() !== "" &&
      questionType !== ""; // Ensure questionType is not empty

    setIsAddQuestionEnabled(isComplete);
  };

  useEffect(() => {
    checkFormCompletion();
  }, [
    assessment.assessmentName,
    assessment.assessmentDescription,
    questionType,
  ]);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleDelete = (indexToDelete) => {
    setQuestionsList((prevQuestions) =>
      prevQuestions.filter((_, index) => index !== indexToDelete)
    );
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (!file) {
        reject();
      }
      fileReader.readAsArrayBuffer(file);

      fileReader.onabort = () => {};

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsQuestions = wb.Sheets["Questionaire"];
        const wsNamelist = wb.Sheets["Namelist"];

        const dataQuestions = XLSX.utils.sheet_to_json(wsQuestions);
        const dataNamelist = XLSX.utils.sheet_to_json(wsNamelist);

        const data = {
          questions: dataQuestions,
          nameList: dataNamelist,
        };
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      console.log(data);
      var questions = [];
      const sampleSizeRatio = [
        {
          idSampleSizeType: 1,
          name: "Boss",
          value: 0,
        },
        {
          idSampleSizeType: 2,
          name: "Peer",
          value: 0,
        },
        {
          idSampleSizeType: 3,
          name: "Project",
          value: 0,
        },
        {
          idSampleSizeType: 4,
          name: "Subordinate",
          value: 0,
        },
        {
          idSampleSizeType: 5,
          name: "customer",
          value: 0,
        },
        {
          idSampleSizeType: 6,
          name: "crossfunction",
          value: 0,
        },
        {
          idSampleSizeType: 7,
          name: "oneself",
          value: 0,
        },
      ];

      const nameListData = data.nameList.map((row, index) => {
        return {
          personId: row["personId"],
          idSampleSizeType: sampleSizeRatio.find(
            (o) => o.name === row["Sample Type"]
          ).idSampleSizeType,
          sampleId: row["sampleId"],
        };
      });
      const fixedData = data.questions.map((row, index) => {
        return {
          nameQuestionaire: row["Name Questionaire"],
          description: row["Description"],
          launchDate: dayjs(
            new Date(Date.UTC(0, 0, row["Launch Date"] - 1))
          ).format("YYYY-MM-DD"),
          endDate: dayjs(new Date(Date.UTC(0, 0, row["End Date"] - 1))).format(
            "YYYY-MM-DD"
          ),
          question: row["Question"],
          sampleSize: row["sampleSize"],
          sampleRatio: row["sampleRatio"],
        };
      });

      const dataShow = {
        nameQuestionaire: fixedData[0].nameQuestionaire,
        description: fixedData[0].description,
        launchDate: fixedData[0].launchDate,
        endDate: fixedData[0].endDate,
      };

      for (let index = 0; index < fixedData.length; index++) {
        const element = fixedData[index];
        questions.push(element.question);

        const _index = sampleSizeRatio.findIndex((object) => {
          return object.name === element.sampleSize;
        });
        if (_index >= 0) {
          sampleSizeRatio[_index].value = element.sampleRatio;
        }
      }

      dataShow.questions = questions;
      dataShow.sampleSizeRatio = sampleSizeRatio;
      dataShow.sampleSize = nameListData;

      console.log(dataShow);

      setExcelData(dataShow);
      handleClickOpen();
    });
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className={`wrapHeader`}>
          <HeaderPage textLabel={"ประเมิน 360 องศา"} icon={iconHeader} />
          <div style={{ marginLeft: "auto" }}>
            {/* <Tooltip title="Upload">
              <label htmlFor="icon-button-file">
                <input
                  hidden
                  accept=".xlsx, .xls, .csv"
                  id="icon-button-file"
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    readExcel(file);
                  }}
                />
                <Button
                  aria-label="upload file"
                  component="span"
                  startIcon={<UploadFileIcon />}
                  variant="contained"
                  style={{ marginLeft: 16,  borderRadius: 8, backgroundColor: "#b58de8",}}
                >
                  Upload
                </Button>
              </label>
            </Tooltip> */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickCreateAssessment}
              style={{ marginLeft: 16,  borderRadius: 8, backgroundColor: "#b58de8",}}
            >
              สร้างชุดคำถามใหม่
            </Button>
          </div>
        </div>
        <div>
          <AssessmentList />
        </div>
      </Container>

      {/* {open && <CreateAssessment />} */}
      <CreateAssessment open={open} handleClose={handleClose} />
    </StyledRoot>
  );
}
