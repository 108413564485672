import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import "chartjs-plugin-doughnutlabel";
import { Divider, Typography, Stack } from "@mui/material";
import SelectAffiliateCompanyDark from "../../shared/general/selectAffiliateCompanyDark";
import CostRewardTable from "./costRewardTable";

const StyledRoot = styled("div")({
  display: "flex",
  paddingTop: 80,
  flexDirection: "column",
  margin: "0px auto",
  minWidth: 350,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 10,
  backgroundColor: "#ffffff2e",
});

export default function CostReward() {
  const [companySelected, setCompanySelected] = useState(null);
  return (
    <StyledRoot className={"page dashboard-page"}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        marginTop={2}
      >
        <Typography style={{ color: "white" }} variant="h4">
          สรุปค่าใช้จ่ายรางวัล
        </Typography>
      </Stack>
      <StyledDivider />
      <div style={{ maxWidth: 320, width: "100%", marginBottom: 24 }}>
        <SelectAffiliateCompanyDark
          value={companySelected}
          onChange={setCompanySelected}
          defaultSelect={true}
        />
      </div>
      <CostRewardTable companySelected={companySelected} />
    </StyledRoot>
  );
}
